/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AppContext from "../AppContext";
import { Api, Lang, Config } from "./../services/Services";
import Filter from "./Filter";
import { compose, LoadProds, MustUser } from './Hocs/Hocs';
import { CircChart, ShopBtn } from "./utils/Utils";
import Store from "./store/Store";

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
  

//games
//popup game
//popup skill
//skills
//amazon popup
//shop light

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);
	
const rand= n =>Math.round(Math.random()*n);


const ArcScore=({score})=>{
	//0->0 0 25
	//16.6->30 3.3 12.5
	//25->45 7.3 7.3
	//33.3->60 12.5 3.3
	//50->90 25 0
	//66.6->120 37.4 3.3
	//75->135 42.6 7.3
	//83.3->150 46.6 12.5
	//100->180 50 25
	const lineWidth=4;

	const radius=21;
	const startDrawX=0;
	const startDrawY=radius;
	const radiusX=radius;
	const radiusY=radius;
	const scoreInDeg=score*180/100;
	const scoreInRad=scoreInDeg*(Math.PI/180);
	const y=radius-Math.sin(scoreInRad)*radius; //25->0->25
	let x=radius-Math.cos(scoreInRad)*radius;	//0->50	
	// if(score>50){
	// 	x=radius*2-x;
	// }
	// console.log(score,scoreInDeg,x,y);
	const dEndX=x;
	const dEndY=y;
	const maxEndX=radius*2;
	const maxEndY=radius;
	// const dEndX=4+Math.round(x);
	// const dEndY=4+Math.round(y);
	return (
		<div className="arc-score" style={{ width:radius*2+lineWidth*2,height:radius+lineWidth }}    >
			<svg width={radius*2+lineWidth*2} height={radius+lineWidth} >
				{/* <circle cx="0" cy="50" r="42"      />
				<circle cx="0" cy="50" r="42" className="blue"      /> */}
				<path style={{transform:`translate(${lineWidth}px,${lineWidth}px)`}} d={`M ${startDrawX} ${startDrawY} A ${radiusX} ${radiusY} 0 0 1 ${maxEndX} ${maxEndY}`} strokeWidth={lineWidth} />
				<path style={{transform:`translate(${lineWidth}px,${lineWidth}px)`}} d={`M ${startDrawX} ${startDrawY} A ${radiusX} ${radiusY} 0 0 1 ${dEndX} ${dEndY}`} strokeWidth={lineWidth} className="blue" />
				{/* <path d=" M 4 100 A 46 46 0 0 1 96 100" className="blue" /> */}

			</svg>
			<div className={score>50?"blue":""}   >{Math.round(score)}%</div>
		</div>
	);
}


const rnd = n =>Math.round(n*100)/100;

const Charts2 = () => {
	const context = useContext(AppContext);
	const user=context.UserService.user;
	const StoreService = context.StoreService;
	
	const [isSkill, isSKillUpd] = useState(null);
	const [games, gamesUpd] = useState(null);
	const [skillsIdNames, skillsIdNamesUpd] = useState(null);
	const [gamesIdNames, gamesIdNamesUpd] = useState(null);
	const [gamesToSkills, gamesToSkillsUpd] = useState(null);
	// const [packageIdsForStore, packageIdsForStoreUpd] = useState([]);
	const [skills, skillsUpd] = useState(null);
	const [dailyAvg, dailyAvgUpd] = useState(null);
	const [dailyAvgLastWeek, dailyAvgLastWeekUpd] = useState(null);
	const [lastWeekSucPerc, lastWeekSucPercUpd] = useState(null);
	const [clickedSkills, clickedSkillsUpd] = useState([]);
	const [isOverlay, isOverlayUpd] = useState(Config.CHARTS2_POPUP);
	const [openedSkill,openedSkillUpd] = useState(null);
	const [openedGame,openedGameUpd] = useState(null);
	const [dataStats,dataStatsUpd] = useState({});
	const [isLoaded, isLoadedUpd] = useState(false);

	
	const products=StoreService.products;
	
	
	const getSkillsForStore=(skills)=>{
		const skillsForStore=[];
		const playedSkills=skills.filter(s=>!s.unplayed);
		if(playedSkills.length){
			for(let i=0;i<playedSkills.length;i++){
				if(i>3){break;}
				skillsForStore.push(playedSkills[playedSkills.length-i-1]);
			}
		}
		skillsForStore.reverse();
		return skillsForStore;
	}
	
	

	const loadData=async ()=>{
		const gamesData=await Api.getChartData2(context.UserService,"games_data");
		// console.log(products);



		const gamesObj={};
		const skillsObj={};
		const gamesToSkillsObj={};
		const gameAndSkillsData=await Api.getGameAndSkillsData(context.UserService);
		if(gameAndSkillsData){

			if(gameAndSkillsData?.games?.length){
				gameAndSkillsData.games.forEach(row=>{
					gamesObj[row["id"]]=row;
				})
			}
			gamesIdNamesUpd(gamesObj);

			if(gameAndSkillsData?.skills?.length){
				gameAndSkillsData.skills.forEach(row=>{
					skillsObj[row["id"]]=row;
				})
			}
			skillsIdNamesUpd(skillsObj);

			if(gameAndSkillsData?.gamesToSkills?.length){
				for(let row of gameAndSkillsData.gamesToSkills){
					if(!gamesToSkillsObj[row.game_id]){
						gamesToSkillsObj[row.game_id]=[];
					}
					gamesToSkillsObj[row.game_id].push({skill_id:row.skill_id,factor:Number(row.factor || 0)});
				}
			}
			gamesToSkillsUpd(gamesToSkillsObj);
		}

		const games=[];
		// console.log(gamesObj);
		gamesData.data.forEach(game=>{
			const actualGame=gamesObj[game.id];
			if(!actualGame){
				console.log("can't find game ",game.id);
				return;
			}
			// console.log(productWithGame);
			// console.log(game.id);
			let perc=0;
			const unplayed=game.enters==0;
			if(game.success || game.fail){
				perc=Math.round(game.success/(game.success+game.fail)*100);
			}
			const name=actualGame.name;
			const description=actualGame.description;
			const image_filename=actualGame.icon;

			games.push({...game,unplayed,perc,name,image_filename,description});
		});
		games.sort((a,b)=>b.enters-a.enters);
		gamesUpd(games);

		
		const skillsData=await Api.getChartData2(context.UserService,"skills_data");
		const skills=skillsData.data.map(skill=>{
			let perc=0;
			let unplayed=true;
			if(skill.success || skill.fail){
				unplayed=false;
				perc=Math.round(skill.success/(skill.success+skill.fail)*100);
			}
			const actualSKill=skillsObj[skill.id];
			
			const name= actualSKill?.name || "";
			const description= actualSKill?.description || "";

			return {...skill,perc,unplayed,name,description};
		});
		skills.sort((a,b)=>b.perc-a.perc);
		skillsUpd(skills);

		const skillsForStore = getSkillsForStore(skills);
		const clickedSkills=skillsForStore.map(it=>it.id)
		clickedSkillsUpd(clickedSkills);


		
		const dailyAvg=await Api.getChartData2(context.UserService,"daily_average_minutes");
		dailyAvgUpd(dailyAvg?.data || 0);
		
		const dailyAvgLastWeek=await Api.getChartData2(context.UserService,"daily_average_minutes_last_week");
		dailyAvgLastWeekUpd(dailyAvgLastWeek?.data || 0);
		
		const lastWeekSucPerc=await Api.getChartData2(context.UserService,"last_week_success_perc");
		lastWeekSucPercUpd(lastWeekSucPerc?.data || 0);




		
		// packageIdsForStoreUpd(packageIdsForStore);
		
		isLoadedUpd(true);


		
	}

	const clickSkill=skill_id=>{
		if(clickedSkills.includes(skill_id)){
			clickedSkillsUpd(clickedSkills.filter(id=>id!=skill_id));
		}
		else{
			clickedSkillsUpd([...clickedSkills,skill_id]);
		}
	}

	const getGameDaily=async game=>{
		console.log(game);
		const stats=await Api.getChartData2(context.UserService,"game_daily",{gameId:game.id});
		dataStatsUpd(stats?.data || {});
		openedGameUpd(game);
		isOverlayUpd(true);
	}
	const getSkillDaily=async skill=>{
		const stats=await Api.getChartData2(context.UserService,"skill_daily",{skillId:skill.id});
		dataStatsUpd(stats?.data || {});
		openedSkillUpd(skill);
		isOverlayUpd(true);
	}
	const options = {
		responsive: true,
		maintainAspectRatio:false,
		plugins: {
		  legend: {
			position: 'top',
		  },
		  title: {
			display: false,
			text: 'Chart.js Bar Chart',
		  },
		  
		},
	};
	  
	const labels = Object.keys(dataStats);

	const percData={};
	labels.forEach(date=>{
		percData[date]={success:0,failure:0};
		const stat=dataStats[date];
		if(stat && stat?.success && stat?.failure){
			const sum=Number(stat.success)+stat.failure;
			percData[date].success=Math.round(stat.success/sum*100);
			percData[date].failure=Math.round(stat.failure/sum*100);
		}

	});

	  
	
	const data = {
		labels,
		datasets: [
		  {
			label: Lang.scoring_charts.success || "",
			data: labels.map(date => percData[date]?.success || 0),
			backgroundColor: 'rgba(53, 162, 235, 0.5)',
		},
		{
			label: Lang.scoring_charts.failure || "",
			data: labels.map(date => percData[date]?.failure || 0),
			backgroundColor: 'rgba(255, 99, 132, 0.5)',
		  },
		],
	};


	useEffect(()=>{
		console.log(111111);
		document.body.style.overflow= isOverlay ? "hidden" : "auto";
		if(isOverlay){
			window.scrollTo(0,0);		
		}
	},[isOverlay])


	useEffect(() => {
		loadData();
	}, []);

	if(!isLoaded){
		return null;
	}

	console.log(Config.CHARTS2_POPUP);
	// console.log(clickedSkills);

	const child = (user && user.kids && user.kids.find(child => child.id === context.UserService.curPlayerId));

	const playedGames=games.filter(g=>!g.unplayed);
	const skillsForStore=getSkillsForStore(skills);
	

	// console.log(gamesToSkills);
	// console.log(gamesIdNames);
	// console.log(skillsIdNames);
	// console.log(games);
	// console.log(skills);
	// console.log(skillsForStore);

	const hoursLastWeekChangePerc=dailyAvgLastWeek ? rnd(((dailyAvg/dailyAvgLastWeek)-1)*100) : 100;
	const avgPerc=playedGames?.length ? Math.round(playedGames.reduce((acc,it)=>acc+it.perc,0)/playedGames.length) : 0;
	const percLastWeekChange=lastWeekSucPerc ? rnd(((avgPerc/lastWeekSucPerc)-1)*100) : 100;
	const antiAvgPerc=100-avgPerc;

	


	// let firstGameMorePerc=0;
	// if(games.length>2){
	// 	firstGameMorePerc=games[1].enters ? rnd(((games[0].enters/games[1].enters)-1)*100) : 100;
	// }

	const packageIdsForStore=products.filter(product=>{
		if(!skillsForStore?.length){return false;}

		const inAccount=(user && user.gameIds && StoreService.userAndProductShareGames(user,product));
		if(inAccount){
			// console.log("in account",product?.text?.name);
			return false;
		}
		//package has games that have ANY of these skill, and skill have factor>2
		if(!product?.games?.length){return false;}

		// console.log(product.games);

		const anyGameHasGoodSkills=product.games.filter(game_id=>{
			const gameToSkillsRows=gamesToSkills[game_id];
			// console.log(gameToSkillsRows);
			if(!gameToSkillsRows?.length){
				// console.log("not found rows for game",game_id);
				return false;
			}
			//filter to only good skills
			const goodSkills=gameToSkillsRows.filter(row=>row?.factor > 4 );
			if(!goodSkills.length){
				// console.log("not found good skills",game_id);
				return false;
			}
			
			//filter to only the currently selected skills
			const sameAsStoreSkills=goodSkills.filter(row=>clickedSkills.includes(row.skill_id));
			if(!sameAsStoreSkills.length){
				// console.log("not found sameAsStoreSkills",game_id);
				return false;
			}
			// console.log(sameAsStoreSkills);
			
			return true;
		})

		if(!anyGameHasGoodSkills.length){
			return false;
		}
		
		// console.log(product);
		return true;
	}).map(p=>p.id);

	const closeOverlay=()=>{
		isOverlayUpd(false);
		openedGameUpd(null);
		openedSkillUpd(null);
		dataStatsUpd({});
	}

	const goToRate=()=>{
		window.location = Config.CHARTS2_POPUP_LINK;
	}

	// console.log(packageIdsForStore);


	// Qs:
	//unplayed only in skills?
	//top game more than others?
	//skills?


	return (
		
		<section className="charts2">
			{isOverlay?
			<div className="overlay">
				<div className={"x-close "+((openedGame || openedSkill)?" gameSkill":"")}  onClick={closeOverlay} ></div>
				{(openedGame || openedSkill) ?
				<>
					<div className="webee-popup" ></div>
					<div className="box gameSkill">
						<div>
							<div >
								<div className="name">
									{(openedGame || openedSkill).name}
									<span> - {Lang.scoring_charts.Success_Failure_timeline}</span>
								</div>
								<div  className="only-lg" dangerouslySetInnerHTML={{ __html: (openedGame || openedSkill).description || "" }}   ></div>						
							</div>
							{openedGame &&
							<div className="gameImg">
								{/* <div className="ages">blah....</div> */}
								<img src={Config.server_url + "images/" +openedGame.image_filename} />
							</div>
							}
							<div  className="non-lg  description "  dangerouslySetInnerHTML={{ __html: (openedGame || openedSkill).description || "" }}></div>
						</div>
						<div className="graphTitle">{Lang.scoring_charts.Success_Failure_timeline}</div>

						<div style={{height:"40vh"}}  >
							<Bar options={options} data={data}  />
						</div>

					</div>
				</>
				:
				<div className="box">
					<div className="title">{Lang.scoring_charts.Enjoying_Webee_World}</div>
					<div  dangerouslySetInnerHTML={{ __html:Lang.scoring_charts.Rate_this_game || ""}} ></div>
					<div className="amazon-popup-image"></div>
					<div className="btns">
						<div onClick={closeOverlay}  >{Lang.scoring_charts.Later}</div>
						<div  onClick={goToRate}  >{Lang.scoring_charts.Rate_now}</div>
					</div>
				</div>
				}
			</div>
			:""
			}

			<div className="img webee"></div>
			

			<div  className="first-row"  >
				<div className="box">	{/* box */}
					<div className="title" >
						<div className="img mobile-screen-time" ></div>
						<div   >{Lang.scoring_charts.screen_time}</div>
					</div>
					<div  className="daily-avg-txt" >
						<div   >{Lang.scoring_charts.daily_avg}</div>
					</div>
					<div className='time-and-perc'  >
						<div className="time-txt" >
							<div >{dailyAvg}</div>
							<div>{Lang.scoring_charts.min}</div>
						</div>
						<div  className="perc-row" >
							<div className={( hoursLastWeekChangePerc>0 ? "success" : "failure" )+"-img"} ></div>
							<div  >{hoursLastWeekChangePerc}% {Lang.scoring_charts.than_last_week}</div>
						</div>
					</div>
				</div>

				<div className="box">
					<div className="title">
						<div className="img faces" ></div>
						<div   >{Lang.scoring_charts.success_failure}</div>
					</div>
					<div  className="improv-perc-row" >
						<div className={( percLastWeekChange>0 ? "success" : "failure" )+"-img"}  ></div>
						<div  >{percLastWeekChange}% {Lang.scoring_charts.improvment_since_last_week}</div>
					</div>	
					<div className="perc-block" >
						<div style={{width:avgPerc+"%"}} >{avgPerc}%</div>
						<div style={{width:antiAvgPerc+"%"}}>{antiAvgPerc}%</div>
					</div>
				</div>

				<div className="box top-game">
					{games.length && 
					<>
						<div className="title" >
							<div className="img flame" ></div>
							<div >{child.name} {Lang.scoring_charts.top_game}  </div>
							<div className="img info" ></div>
							{/* <div className="details" >Details &gt;</div> */}
						</div>
						<div className="game">
							<img src={Config.server_url + "images/" +games[0].image_filename} />
							<div>
								<div>{games[0].name}</div>
								<div>{games[0].enters} {Lang.scoring_charts.times_this_week}</div>
								{/* <div>
									<div className="img warning"></div>
									<div>{firstGameMorePerc}% {Lang.scoring_charts.more_than_others}</div>
								</div> */}
							</div>

						</div>
					</>				
					}	
				</div>

			</div>

			<div className="switch">
				<div  onClick={()=>isSKillUpd(true)} className={(isSkill?"active":"")} >
					<div>
						<div className={"img medal-tab "+(isSkill?"active":"")}  ></div>
						<div>{Lang.scoring_charts.achievments_by_skills}</div>
					</div>
				</div>
				<div onClick={()=>isSKillUpd(false)} className={(isSkill?"":"active")} >
					<div>
						<div className={"img flame-tab "+(isSkill?"":"active")} ></div>
						<div>{Lang.scoring_charts.most_played_games}</div>
					</div>
				</div>
			</div>


			<div className={"box "+(isSkill?"skills":"games")+"-row"}>
				{isSkill?
				skills.map((skill,key)=>{
					return (
						<div  key={key}  onClick={()=>getSkillDaily(skill)}   >
							<div>{skill.name}</div>
							{skill.unplayed?
							<>
								<div className="unplayed">{Lang.scoring_charts.unplayed}</div>
								<div >
									<div className="img mobile-sad"></div>
								</div>
							</>
							:
							<div>
								<ArcScore score={skill.perc} />
							</div>
							}
							<div >
								<div className="img arrow-side"></div>
							</div>
						</div>
					)	
				})				
				:
				games.map((game,key)=>{
					return (
						<div  key={key} onClick={()=>getGameDaily(game)} >
							<div>{key+1}.</div>
							<div>
								<img src={Config.server_url + "images/" +game.image_filename} />
							</div>
							<div>
								<div>{game.name}</div>
								<div>{game.enters} {Lang.scoring_charts.times_this_week}</div>
							</div>
							{game.unplayed?
							<>
								<div className="unplayed">{Lang.scoring_charts.unplayed}</div>
								<div >
									<div className="img mobile-sad"></div>
								</div>
							</>
							:
							<div>
								<ArcScore score={game.perc} />
							</div>
							}
							<div >
								<div className="img arrow-side"></div>
							</div>
						</div>
	
					)	
				})
			}
			</div>		

			<div className="store-container-row">
				<div className="box">
					<div className="title" >
						<div className="img mobile-shopping-cart" ></div>
						<div >{Lang.scoring_charts.recommendations_of_games_for} {child.name}</div>
					</div>
					<div className="skills">
						{skillsForStore.map((skill,key)=>
						<div key={key}>
							{/* <div className={"img mobile-checkbox-"+(rand(100)>50?"unactive":"active")} ></div> */}
							<div className={"img mobile-checkbox-"+( clickedSkills.includes(skill.id) ?"active":"unactive")} onClick={()=>clickSkill(skill.id)} ></div>
							<div >{skill.name}</div>
						</div>
						)}
					</div>
					<div className="store-container-part">
						<Store key={packageIdsForStore} forcedIds={packageIdsForStore} />
					</div>
				</div>

			</div>

			<div style={{height:100}}></div>


		</section>
	)
}

export default compose(LoadProds, MustUser, Charts2);