import React,{useState,useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { compose, GenForm, MustAdminPass } from '../Hocs/Hocs';
import {Api,ModalService} from "./../../services/Services";
import {BaseSubmit,BaseWrap,RegiBtns} from "./../utils/Utils";
import AdminMenu from "./AdminMenu";

const fields=[
	{th:"Email",name:"name",toLowerCase:true},
	{th:"Amount",name:"amount"},
	{th:"Date",name:"date"},
];
const apiFunc=async (sendObj)=>await Api.processInvoices(sendObj);

const Inovoices=({errors,submit,servError})=>{
	const [disabled,disabledUpd]=useState(false);
	const [invoices,invoicesUpd]=useState([]);
	const [checkedIds,checkedIdsUpd]=useState([]);

	const load=async ()=>{
		let res=await Api.getInvoices();
		if(res.invoices){
			invoicesUpd(res.invoices);

		}
		disabledUpd(false);
	}
	
	
	useEffect(()=>{
		load();
	},[]);

	const submitFunc=async (isWithSend=false)=>{
		if(checkedIds.length){
			disabledUpd(true);
			let sendObj={};
			checkedIds.forEach(it=>sendObj["invoice:"+it]=1);
		 	checkAll(false);

			sendObj[isWithSend?"send":"mark"]=1;
			await submit(sendObj);
			load();
		}
	}

	// const submit=async (isWithSend=false)=>{
	// 	if(checkedIds.length){
	// 		servErrorUpd(null);
			
	// 		console.log(sendObj);
	// 		let res=await Api.processInvoices(sendObj);
	// 		if(res.success){
	// 			ModalService.openToast("Save Successful");
	// 		}
	// 		else{
	// 			servErrorUpd("Save Failed");
	// 		}
	// 	}
	// }

	const checkAll=isChecked=>{
		let checkedIds=(isChecked?invoices.map(it=>it.id):[]);
		checkedIdsUpd(checkedIds);
		console.log(checkedIds);	
	}
	const checkId=(id)=>{
		let ids=[...checkedIds];
		if(!ids.includes(id)){
			ids.push(id);
		}
		else{
			ids=ids.filter(it=>it!==id);
		}
		checkedIdsUpd(ids);
	}
		
	return (
		<BaseWrap items={<AdminMenu  />}  >
			<div style={{width:"80%"}}>
				<h1>Invoices</h1>
				<div style={{marginBottom:20}} >
					<table border="1">
						<thead>
							<tr>
								<th>#</th>
								{fields.map(it=><th key={it.name}  >{it.th}</th>)}
								<th><input type="checkbox"  onClick={e=>checkAll(e.target.checked)} /></th>
							</tr>
						</thead>
						<tbody>
							{invoices.map((inv,k)=>
								<tr key={inv.id} >
									<td>{k+1}</td>
									{fields.map(it=><td key={it.name}  >{inv[it.name]}</td>)}
									<td>
										<input type="checkbox"   checked={checkedIds.includes(inv.id)} onChange={e=>checkId(inv.id)}  />
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
				<RegiBtns servError={servError} >
					<BaseSubmit disabled={disabled} onClick={()=>submitFunc(true)} label={"Send"}  />
					<BaseSubmit disabled={disabled} onClick={()=>submitFunc(false)} label={"Just Mark"}  />
				</RegiBtns>
			</div>
		</BaseWrap>
	);
}

export default GenForm(compose(MustAdminPass,Inovoices),{fields,apiFunc});