import React, { useContext, useState,useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import AppContext from "../../AppContext";
import { SelectGen, InputDiv, Error } from "../utils/Utils"
import { FormService,Lang,Config,Api, ModalService} from "../../services/Services";
import Coupon from "./Coupon"; 
import {LoadProds,MustUser,compose} from '../Hocs/Hocs';


const Checkout = () => {
	const context = useContext(AppContext);
	const history = useHistory();
	const months = Array(12).fill(true).map((it, k) => k + 1).map(it => ({ key: it, value: it }));
	const thisYear = (new Date()).getFullYear();
	const years = Array(10).fill(true).map((it, k) => k + thisYear).map(it => ({ key: it, value: it }));
	const cost = context.StoreService.getCartCost();

	const fieldsObj = {};
	fieldsObj.creditCard = useState("");
	fieldsObj.month = useState(1);
	fieldsObj.year = useState(thisYear);
	fieldsObj.cvv = useState("");
	const [servError,servErrorUpd] = useState(null);
	const [errors, errorsUpd] = useState({});
	const [submitDisabled,submitDisabledUpd]=useState(false);
	const [coupon, couponUpd] = useState("");
	const hasProducts=context.StoreService.hasProducts();
	const [couponOpen,couponOpenUpd]=useState(false);


	const fields = [
		{ name: "creditCard", label: "Credit Card", placeholder: "#", validation: { notEmpty: true, length: 16 } },
		{ name: "month", label: "Month", type: "select", opts: months, validation: {} },
		{ name: "year", label: "Year", type: "select", opts: years, validation: {} },
		{ name: "cvv", label: "CVV", placeholder: "###", validation: { notEmpty: true, length: 3 } },
	];
	FormService.fieldsHoist(fields, fieldsObj);


	const submit = async () => {
		if(cost>0 && FormService.validateEntireFormHooks(fields, errorsUpd)){
			return;
		}
		submitDisabledUpd(true);
		let sendObj={
			prods: context.StoreService.getCartIdsForCheckout(),
			gopay: "pay",
			fldShipFirstName:"",
			fldShipLastName:"",
			fldShipEmail:"",
			fldShipPhone:"",
			fldShipAddress1:"",
			fldShipAddress2:"", 
			fldShipCity:"",
			fldShipStateProvince:"",
			fldShipCountry:"",
		};
		if(cost>0){
			sendObj={...sendObj,
				fldCardNumber: fieldsObj.creditCard[0],
				fldExpirationYear: fieldsObj.year[0],
				fldExpirationMon: fieldsObj.month[0],
				fldCardCVV: fieldsObj.cvv[0],
			}
		}

		if(context.StoreService.curCoupon.code){
			sendObj.coupon=context.StoreService.curCoupon.code;
		}
		// console.log(sendObj);
		servErrorUpd(null);
		let res=await Api.checkout(context.UserService,sendObj);
		// let res={success:true};
		// console.log(res);
		if(res.success){
			window.gtag_report_conversion();
			context.UserService.appendGamesToUser(context.StoreService.getCartGames());
			context.StoreService.resetCart();
			context.StoreService.resetCoupon();
			context.StoreService.resetProds();
			ModalService.openToast(Lang.checkout_payment.heading_trx_completed);
			history.push("/products");
		}
		else{
			submitDisabledUpd(false);
			if(res.err){
				servErrorUpd(res.err);
			}
			else{
				servErrorUpd(Lang.checkout_payment.err_card_proc_faild);
			}
		}
		// console.log("purchased!");
		// context.StoreService.resetCoupon();
	}

	if(!hasProducts){return null;}

	return (

		<section className="checkout pad">
			<div className="top-wrap">
				<div className="top-area-with-btn">
					<Link to="/cart" >
						<span className="back-btn"></span>
					</Link>
					<div className="texts" >
						<h4>{Lang.checkout_payment.heading_shipping}</h4>
					</div>
				</div>
			</div>

			<div className="payment-form">
				<div className="wrap-div-sp" >
					<label>{Lang.checkout_payment.label_card_number}</label>
					<div>
						<InputDiv  fieldObj={fields[0]} value={fields[0].value} error={errors[fields[0].name]} />
					</div>
				</div>
				<div className="exp-cont">
					<label>{Lang.checkout_payment.label_card_expiration}</label>
					<div className="exp" >
						<SelectGen fieldObj={fields[1]} value={fields[1].value} />
						<SelectGen fieldObj={fields[2]} value={fields[2].value} />
					</div>
				</div>
				<div className="cvv wrap-div-sp">
					<label>{Lang.checkout_payment.label_card_cvv}</label>
					<div>
						<InputDiv  fieldObj={fields[3]} value={fields[3].value} error={errors[fields[3].name]} />
					</div>
				</div>

				<div className="divider"></div>

				<div className="coupon-cont" >
					<label className="underline"  onClick={()=>couponOpenUpd(!couponOpen)} >{Lang.get_coupon.heading}</label>
					{couponOpen && 
					<Coupon couponUpd={couponUpd} />
					}
				</div>
				<div className="payment-cont" >
					<h2>{Lang.checkout_payment.heading_total_sum}  : <span  dangerouslySetInnerHTML={{__html:Config.DEFAULT_CURRENCY_SYMBOL+""+cost}}></span></h2>

					<Error err={servError} isBtn />
					<button className="btn" onClick={submit} disabled={submitDisabled} >{Lang.checkout_payment.butten_submit} </button>
				</div>
			</div>
			<div className="payment-bottom" >
				
			
				<div className="img-div" >
					<a href="#"><img src="/assets/images/godaddysecured.png" alt="godaddy verified and secured" /></a>
					<a href="#" ><img src="/assets/images/mcafee.png" alt="mcafee security" /></a>
				</div>
				<div className="bottom-text">
					<p dangerouslySetInnerHTML={{__html:Lang.checkout_payment.footer_text}}></p>
				</div>
			</div>
			
		</section>
	)
}


export default compose(MustUser,LoadProds,Checkout);