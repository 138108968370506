import React,{useState} from 'react';
import { useHistory } from 'react-router-dom';
import { compose, GenForm, MustAdminPass } from '../Hocs/Hocs';
import {FormService,Api,ModalService, Lang} from "./../../services/Services";
import {BaseSubmit,BaseWrap,InputDiv,Error, RegiBtns} from "./../utils/Utils";
import AdminMenu from "./AdminMenu";

const fieldsObj={};
const fields=[
	{name:"codeCount",validation:{notEmpty:true,numberPositive:true},label:"Code Count",placeholder:"enter"},
	{name:"codesPerPackage",validation:{notEmpty:true,numberPositive:true},label:"Codes per Package",placeholder:"enter"},
];
const apiFunc=async()=>{
	let sendObj=fields.reduce((acc,it)=>({...acc,[it.name]:it.value}),{});
	return await Api.crActivationCode(sendObj);
}

const CrActivationCodes=({errors,submit,servError})=>{
	
	fieldsObj.codeCount=useState("1");
	fieldsObj.codesPerPackage=useState("1");
	FormService.fieldsHoist(fields,fieldsObj);

	return (
		<BaseWrap items={<AdminMenu  />}  >
			<div style={{width:"50%"}}>
				<h1>Create Activation Codes</h1>
				<h4>It is NOT RECOMMENDAED to request more than 500 codes at once</h4>
				
				<ul className="form "  >
					{
						fields.map(fieldObj=><li key={fieldObj.name}><InputDiv doLabel  fieldObj={fieldObj}  error={errors[fieldObj.name]}  /></li>)
					}
				</ul>
				<RegiBtns servError={servError} >
					<BaseSubmit onClick={submit}  />
				</RegiBtns>
			</div>
		</BaseWrap>
	);
}

export default GenForm(compose(MustAdminPass,CrActivationCodes),{fields,apiFunc});