import { Subject } from 'rxjs';

class ModalService{
	static modalChangedSubject=new Subject();
	static toastSubject=new Subject();
	static blueCoverSubject=new Subject();


	static openModal(modalName){
		this.modalChangedSubject.next(modalName);
	}
	static close(){
		this.modalChangedSubject.next(false);
	}
	static openToast(text){
		this.toastSubject.next(text);
	}
	static openBlueCover(content){
		this.blueCoverSubject.next(content);
	}
	static closeBlueCover(){
		this.blueCoverSubject.next(null);
	}
	

}
	 
export default ModalService;