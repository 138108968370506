import React, { useContext, useState } from 'react';
import AppContext from '../../AppContext';
import { FormService, Api, ModalService,Lang } from "./../../services/Services";
import { InputDiv } from "./../utils/Utils";


const Coupon = ({couponUpd,exClass=""}) => {
	const context=useContext(AppContext);
	const fieldsObj = {};
	fieldsObj.coupon1 = useState("");
	fieldsObj.coupon2 = useState("");
	fieldsObj.coupon3 = useState("");
	const [errors, errorsUpd] = useState({});

	const fields = [
		// { name: "coupon", label: "coupon", placeholder: "AAA-BBBBB-CCC", validation: { notEmpty: true, activationCode: true } },
		{ name: "coupon1", placeholder: "####",toUpperCase:true, validation: { } },
		{ name: "coupon2", placeholder: "####", toUpperCase:true,validation: { } },
		{ name: "coupon3", placeholder: "####", toUpperCase:true,validation: { } },

	];
	FormService.fieldsHoist(fields, fieldsObj);

	const submit = async () => {
		let coupon=fields.map(it=>it.value).join("-");
		console.log(coupon);
		// if (!FormService.validateEntireFormHooks(fields, errorsUpd)) {
		let res = await Api.getCoupon(coupon);
		if (res.success) {
			context.StoreService.updateCoupon(coupon,res.price);
			couponUpd(coupon);
		}
		ModalService.openToast(res.success ?Lang.errorTexts.coupon_suc :Lang.errorTexts.coupon_fail);
	}

	return (
		<div className="coupon" >
			<div>
			{
				fields.map((fieldObj,index)=>
					<React.Fragment key={fieldObj.name} >
						{index?<div className="hyphen">-</div>:""}
						<InputDiv  fieldObj={fieldObj} error={errors[fieldObj.name]} />
						
					</React.Fragment>
				)
			}
			</div>
			<button  onClick={submit} className="btn" >{Lang.get_coupon.butten_find}</button>
		</div>
	)

}

export default Coupon;
