import Axios from "axios";
import {Config} from  "./Services";

// console.log(process.env);
//change 
export const server=(process.env.REACT_APP_SERVER || window.location.protocol+"//"+window.location.host).trim();
const serverApi=`${server}/v1.0/include/api-services/api.php`;
export const imagesFolder=`${server}/v1.0/images`;
const postHeaders={withCredentials: true,headers: {'content-type': 'multipart/form-data'}};
const getHeaders={withCredentials: true};

class Api{
	static adminPassword="";

	static genGetObj(action,obj){
		obj["json"]=1;
		obj["action"]=action;
		obj["dummy"]=(new Date()).getTime();
		obj["langId"]=Config.langId;
		return Object.keys(obj).map(k=>`${k}=${obj[k]}`).join("&");
		
	}
	static genGet(action,obj){
		let search=this.genGetObj(action,obj);
		return new Promise((res,rej)=>{
			Axios.get(`${serverApi}?${search}`,getHeaders).then(r=>res(r.data)).catch(rej)
		});
	}
	static genPostObj(action,obj){
		obj["json"]=1;
		obj["action"]=action;
		obj["dummy"]=(new Date()).getTime();
		obj["langId"]=Config.langId;
		let formData=new FormData();
		Object.keys(obj).forEach(k=>formData.append(k,obj[k]));
		return formData;
	}
	static genPost(action,obj){
		let formData=this.genPostObj(action,obj);
		return new Promise((res,rej)=>{
			Axios.post(`${serverApi}`,formData,postHeaders).then(r=>res(r.data)).catch(rej)
		});
	}

	static postGetFile(action,obj,fileName){
		let formData=this.genPostObj(action,obj);
		let headers={...postHeaders,responseType:"blob"};
		return new Promise((res,rej)=>{
			Axios.post(`${serverApi}`,formData,headers).then(response=>{
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileName); //or any other extension
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				res({success:true});
			});
		});
	}

	static getLang(){
		return this.genPost("getLang",{});
	}
	static getLangForRct(){
		return this.genPost("getLangForRct",{});
	}
	static getConfig(){
		return this.genPost("getConfig",{});
	}
	static getCmsPage(pageName){
		return this.genPost("getCmsPage",{pageName});
	}


	static login(email,password){
		return this.genPost("login",{userID:email,passWD:password});
	}
	static forgotPassword(email){
		return this.genPost("forgotPassword",{email});
	}
	static activationCode(activetionKey){
		return this.genPost("activationCode",{activetionKey});
	}
	static getAccountObject(id){
		return this.genPost("getAccountObject",{id});
	}
	static getAllPackages(){
		return this.genGet("getAllPackages",{});
	}
	static getPackage(id){
		return this.genGet("getPackage",{id});
	}
	static getRecommendGames(userService){
		const [accountId,playerId]=userService.getAccountAndPlayer();	//4
		return this.genPost("getRecommendGames",{accountId,playerId});
	}
	static getRecommendSkills(userService){
		const [accountId,playerId]=userService.getAccountAndPlayer();
		return this.genPost("getRecommendSkills",{accountId,playerId});
	}
	// static getSkillsWithNames(userService){
	// 	const [accountId,playerId]=userService.getAccountAndPlayer();
	// 	return this.genPost("getSkillsWithNames",{accountId,playerId});
	// }
	// static getGamesToSkills(userService){
	// 	const [accountId,playerId]=userService.getAccountAndPlayer();
	// 	return this.genPost("getGamesToSkills",{accountId,playerId});
	// }
	static getGameAndSkillsData(userService){ 
		const [accountId,playerId]=userService.getAccountAndPlayer();
		return this.genPost("getGameAndSkillsData",{accountId,playerId});
	}
	static getChartData(userService,type,resolution,weighting=0){
		const [accountId,playerId]=userService.getAccountAndPlayer();
		return this.genPost("getChartData",{accountId,playerId,type,resolution,weighting});
	}
	static getChartData2(userService,type,otherData={}){
		const [accountId,playerId]=userService.getAccountAndPlayer();
		return this.genPost("getChartData2",{accountId,playerId,type,...otherData});
	}
	static getCoupon(coupon){
		return this.genPost("getCoupon",{coupon});
	}
	static checkout(userService,sendObj){
		const [accountId,playerId]=userService.getAccountAndPlayer();
		return this.genPost("checkout",{...sendObj,accountId,playerId});
	}




	static updateChildren(userService,sendObj){
		const [accountId,playerId]=userService.getAccountAndPlayer();
		return this.genPost("updateChildren",{accountId,playerId,...sendObj});
	}
	static updateAccount(userService,updObj){
		const [accountId,playerId]=userService.getAccountAndPlayer();
		return this.genPost("updateAccount",{accountId,playerId,...updObj});
	}
	static updatePassword(userService,passWD){
		const [accountId,playerId]=userService.getAccountAndPlayer();
		return this.genPost("updatePassword",{accountId,playerId,passWD});
	}
	static regi(sendObj){
		return this.genPost("regi",sendObj);
	}

	//admins
	static addAdminPass(obj){
		return {...obj,adminPassword:this.adminPassword}
	}

	static adminLogin(adminPassword){
		
		return this.genPost("adminLogin",{adminPassword});
	}
	static crCoupon(sendObj){
		sendObj=this.addAdminPass(sendObj);
		return this.postGetFile("crCoupon",sendObj,"Coupons.txt");
	}
	static crActivationCode(sendObj){
		sendObj=this.addAdminPass(sendObj);
		return this.postGetFile("crActivationCode",sendObj,"Codes.txt");
	}
	static getInvoices(){
		let obj=this.addAdminPass({});
		return this.genPost("getInvoices",obj);
	}
	static getCodePackages(){
		let obj=this.addAdminPass({});
		return this.genPost("getCodePackages",obj);
	}
	static updCodePackages(sendObj){
		let obj=this.addAdminPass(sendObj);
		return this.genPost("updCodePackages",obj);
	}
	static processInvoices(sendObj){
		sendObj=this.addAdminPass(sendObj);
		return this.genPost("processInvoices",sendObj);
	}
	static getLangAreas(){
		let obj=this.addAdminPass({});
		return this.genPost("getLangAreas",obj);
	}
	static getLangTermsByArea(area){
		let sendObj=this.addAdminPass({area});
		return this.genPost("getLangTermsByArea",sendObj);
	}
	static updLangTerm(sendObj){
		sendObj=this.addAdminPass(sendObj);
		return this.genPost("updLangTerm",sendObj);
	}
	static getStripeClientSecret(userService,sendObj){
		const [accountId,playerId]=userService.getAccountAndPlayer();
		return this.genPost("getStripeClientSecret",{accountId,playerId,...sendObj});
	}

	



	static getCacheList(){
		return this.genPost("cacheList",{});
	}
	static getCacheItem(item){
		const addr=`${server}/v1.0/games_engine/root/navigation/icons/jpg/${item}`;
		let img=document.createElement("IMG");
		img.style.display="none";
		img.src=addr;
		img.addEventListener("load",event=>event.target.remove());
		document.body.appendChild(img);
	}


	static getGamesNavigation(id){
		return this.genGet("getGamesNavigation",{account_id:id});
	}
	
}


export default Api;