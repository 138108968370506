/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState,useContext } from "react";
import { useHistory, Link } from 'react-router-dom';
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import AppContext from "../AppContext";
import Coupon from "./store/Coupon"; 
import { FormService,Lang,Config,Api, ModalService} from "../services/Services";




const StripeForm=({couponUpd})=> {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [couponOpen,couponOpenUpd]=useState(false);

  const history = useHistory();



  const context = useContext(AppContext);


  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage(Lang.checkout_payment.payment_succeeded);

          window.gtag_report_conversion();
          context.UserService.appendGamesToUser(context.StoreService.getCartGames());
          context.StoreService.resetCart();
          context.StoreService.resetCoupon();
          context.StoreService.resetProds();
          ModalService.openToast(Lang.checkout_payment.heading_trx_completed);
          history.push("/products");


          // setTimeout(()=>{
          //   window.location.replace("/products/0");
          // },Config.STRIPE_SUCCESS_REDIRECT_SECONDS*1000);
          break;
        case "processing":
          setMessage(Lang.checkout_payment.your_payment_is_processing);
          break;
        case "requires_payment_method":
          setMessage(Lang.checkout_payment.your_payment_not_successful);
          break;
        default:
          setMessage(Lang.checkout_payment.something_went_wrong);
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.origin+window.location.pathname,
		receipt_email:context.UserService?.user?.email || "",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };


  return (
	<div style={{display:"flex",justifyContent:"center",alignItems:"center",marginBlockStart:60}}  >
		<form id="payment-form" onSubmit={handleSubmit}>

				


		<PaymentElement id="payment-element" />
		{/* <div className="coupon-cont" >
			<label className="underline"  onClick={()=>couponOpenUpd(!couponOpen)} >{Lang.get_coupon.heading}</label>
			{couponOpen && 
			<Coupon couponUpd={couponUpd} />
			}
		</div> */}
		<button disabled={isLoading || !stripe || !elements} id="submit">
			<span id="button-text">
			{isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
			</span>
		</button>
		{/* Show any error or success messages */}
		{message && <div id="payment-message">{message}</div>}
		</form>
	</div>
  );
}

export default StripeForm;