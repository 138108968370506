/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState, useContext, useEffect } from 'react';
import { compose, LoadProds, MustUser } from './Hocs/Hocs';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Api from '../services/Api';
import StripeForm from './StripeForm';
import AppContext from "../AppContext";


const stripePromise = loadStripe("pk_live_51KgIK3G1LUTYqKCJteXARtRNnHz2KAJaUKgfAN7qVJDp8Xw52LNJyFaHwJabTqeuBnBD2ZdjVLoxOUYCuWhVoLsZ00mR2PSu5s");

const Stripe = () => {
	const [clientSecret, setClientSecret] = useState("");
	const context = useContext(AppContext);
	const [coupon, couponUpd] = useState("");


	useEffect(() => {
		
		load();
	}, []);

	const load=async ()=>{
		const sendObj={
			prods: context.StoreService.getCartIdsForCheckout(),
			coupon,
		};
		const res=await Api.getStripeClientSecret(context.UserService,sendObj);
		if(res?.clientSecret){
			setClientSecret(res.clientSecret);
		}
	}


	const appearance = {
		theme: 'stripe',
	  };
	  const options = {
		clientSecret,
		appearance,
	  };
	
	  return (
		<div className="App">
		  {clientSecret && (
			<Elements options={options} stripe={stripePromise}   >
			  <StripeForm couponUpd={couponUpd}  />
			</Elements>
		  )}
		</div>
	  );
}

export default compose(LoadProds, MustUser, Stripe);