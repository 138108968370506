import React,{useState,useContext} from 'react';
import AppContext from "../../AppContext";
import { GenForm } from '../Hocs/Hocs';
import {FormService,Api,ModalService,Lang} from "./../../services/Services";
import {BaseSubmit,InputDiv,RegiBtns} from "./../utils/Utils";

const fieldsObj={};
const fields=[
	{name:"email",validation:{isEmail:true,notEmpty:true},labelLang:["forgotten_password","Email"],placeholderLang:["forgotten_password","Email"],toLowerCase:true},
];

const apiFunc=async()=>Api.forgotPassword(fieldsObj.email[0]);

const ForgotPassword=({errors,submit,servError,closeForgot})=>{
	const context=useContext(AppContext);
	const [submitDisabled,submitDisabledUpd]=useState(false);
	
	
	fieldsObj.email=useState("");
	const submitFunc=async ()=>{
		submitDisabledUpd(true);
		let res=await submit();
		if(res.success){
			closeForgot();
		}
		else{
			submitDisabledUpd(false);
		}
	}
	
	FormService.fieldsHoist(fields,fieldsObj);

	if(context.UserService.user){return null;}
	return (
		<>
			<h5>{Lang.forgotten_password.Forgot_your_password}</h5>
			<ul className="form">
				{
					fields.map(fieldObj=><li key={fieldObj.name} ><InputDiv   fieldObj={fieldObj}  error={errors[fieldObj.name]}  /></li>)
				}
			</ul>
			<RegiBtns servError={servError} >
				<BaseSubmit onClick={submitFunc} disabled={submitDisabled} label={Lang.forgotten_password.Get_password}  />
			</RegiBtns>
		
		</>
	);
	
}

export default GenForm(ForgotPassword,{fields,apiFunc,langFail:["errorTexts","sent_fail"],langSuc:["errorTexts","sent_suc"]});
