import React from 'react';


const BaseWrap=({width=40,children,items})=>{
	return (
		<section className="admin pad">
			<div className="btns">
				{items}
			</div>
			<div className="main">
				{children}
			</div>
		</section>
	)
}
export default BaseWrap;