import React, { useEffect,useContext,useState  } from 'react';
import AppContext from "../../AppContext";
import { ModalService } from '../../services/Services';
import LoginScreen from '../user/LoginScreen';

const MustUser=(Component)=>{
	const RetComponent=(props)=>{
		const context=useContext(AppContext);
		
		if(!context.UserService.user){
			setTimeout(()=>{
				context.UserService.opLoginScreen(false);
			})
			return null;
		}
		return <Component {...props} />
	}
	return RetComponent;
}

export default MustUser;