const hostname=window.location.hostname;
const exCookie=`path=/;${hostname=="localhost"?"":`Domain=.${hostname};`}`;

class CookieService{
	static getCookie(name){
		let cookies=document.cookie.split(';');
		for(let c of cookies){
			c=c.trim();
			if(c.startsWith(name+'=')){
				let arr=c.split("=");
				if(arr.length>1){
					return arr[1].trim();
				}
			}
		}
		return "";
	}
	static setCookie(name,value,date){
		if(date===undefined){
			date = new Date();
			date.setFullYear(date.getFullYear() +1);
		}
		document.cookie = `${name}=${value};${exCookie}; expires=${date.toGMTString()};`;
	}
	static deleteCookie(name){
		document.cookie = `${name}=;${exCookie}; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
	}
}


export default CookieService;