import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormService, Lang } from "../../services/Services";
import IconSelect from "./IconSelect";

const KidRow = props => {
	const row = props.row;
	const birthday = FormService.getLocalDate(row.birthday);
	return (
		<li className="kid-row">
			<div   >
				<input className="form-control" placeholder={Lang.my_account.label_child_name} defaultValue={row.name} onChange={e => props.onChange ? props.onChange(row, "name", e.target.value) : ""} />
			</div>
			<div  >
				<input type="date" className="form-control" placeholder={Lang.my_account.label_date_of_birth} defaultValue={row.birthday} onChange={e => props.onChange ? props.onChange(row, "birthday", e.target.value) : ""} />



				{/* <DatePicker selected={birthday}	placeholderText={Lang.my_account.label_date_of_birth} onChange={date=>props.onChange?props.onChange(row,"birthday",FormService.dateToStr(date)):""} style={{width:"100px"}} /> */}
			</div>
			<div>
				<select className="form-control" defaultValue={row.gender} onChange={e => props.onChange ? props.onChange(row, "gender", e.target.value) : ""} >
					{Lang.global_genders.map(obj => <option key={obj.key} value={obj.key} >{obj.value}</option>)}

				</select>
			</div>
			<div  >
				<IconSelect gender={row.gender} val={row.icon} onChange={val => props.onChange ? props.onChange(row, "icon", val) : ""} />
			</div>
		</li>
	)
}


export default KidRow;