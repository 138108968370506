import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import AppContext from "./AppContext";
import { UserService, StoreService, Lang, Config, ModalService, Api } from "./services/Services";




import Menu from "./components/menus/Menu";
import ToastContainer from "./components/utils/ToastContainer";
import Routes from "./Routes";
import ErrorBoundary from './components/utils/ErrorBoundary';

const contextObj = {
	UserService: new UserService(),
	StoreService: new StoreService(),
};

class CacheList{
	static initMs=4000;
	static betweenMs=500;
	static prep(){
		if(!localStorage.getItem("navImagesCache")){return}
		setTimeout(CacheList.run,CacheList.initMs);
	}
	static async run(){
		const list=await Api.getCacheList();
		console.log(list);
		for(let item of list){
			await new Promise(resolve=>{
				setTimeout(()=>{
					Api.getCacheItem(item);
					resolve()
				},CacheList.betweenMs);
			});
		}
	}
}



const App = () => {
	const [canLoad, canLoadUpd] = useState(false);
	const [blueCoverState, blueCoverStateUpd] = useState({ isOpen: false });
	useEffect(() => {
		load();
		CacheList.prep();
		const obser = ModalService.blueCoverSubject.subscribe(content => {
			let isOpen = !!content;
			// document.body.classList[isOpen?"add":"remove"]("bg-blue");
			blueCoverStateUpd({ isOpen, content });
		});
		return () => { obser.unsubscribe() }
	}, []);
	const load = async () => {
		await contextObj.UserService.checkAccountAndPlayerCookies();
		await Config.loadConfig();
		await Lang.loadLang();
		
		// console.log(Object.keys(Lang));
		canLoadUpd(true);


	}
	return (
		<ErrorBoundary>
			<Router>
				{canLoad &&
					<AppContext.Provider value={contextObj} >
						<Menu />
						{blueCoverState.isOpen ?
							blueCoverState.content :
							<Routes />
						}

						{/* <Modal /> */}
						<ToastContainer />
					</AppContext.Provider>
				}
			</Router>
		</ErrorBoundary>
	);
}

export default App;



/*


Design:


games_engine/ change images?
defaultChecked/defaultValue change
functionality check (checkout, admin)
checkout needs GenField?

web-worker for slower loads etc
typescript

go back to all validation issues (lits)


does paying receiving a coupon?
what's going on with all the other fields? (shipping etc)

English bugs:
no stars in same products

content editing in admin?
coupon works correctly
design overview

checkout (until api to cardcom)
game page (json from api)

actual check all functions with Racheli
TOKEN BASED SECURITY!!!

reporting functionality to api
Node server

get rid of old_ files


API:
https://isr.webee-world.com
-Login	GET => {success,code?}
-Forgot password POST /v1.0/shield/forgotten_password.php {email} {success,code?}
-Activation code  GET /v1.0/shield/activate_login.php {activetionKey} {success,code}
-store get items (with filters) (changes info when logged) (NOT IMPLEMENTED) GET v1.0/productsCatalog.php {filter?}
-get game-package (NI) GET v1.0/packageDetails.php {id,ap}
-regi (post 3 tabs wizard) (might be errors) (NI) POST v1.0/shield/activate1.php
{
	create: true
	activetionKey: AAA-BBBBB-CCC
	userID: test2@jumboard.com
	passWD: asdasd123
	phoneNU: 34324324324
	country: AF
	emalUP: true
	childName[0]: dsfdsf
	birthDate[0]: 2020-10-06
	gender[0]: M
	img-index[0]: 0
}

Logged apis:
-get suggestions POST /v1.0/services.php {request_type:recommend_games,resolution?:7}
-get graph info POST /v1.0/services.php {request_type,resolution}
-settings changes (like regi tabs) (NI) POST v1.0/services.php
{
	request_type: update-children
	data[0][name]: שחר
	data[0][dob]: 2004-05-28
	data[0][gender]: male
	data[0][img_idx]: 0
	data[0][cid]: d4aa271d-d265-dc5f-986e-642878cffb68 OR +
	data[0][rowid]: 0   (0,1,2,3..)
	dummy: 1602866529395
}
{
	request_type: update-account
	userID: test2@jumboard.com
	phoneNU: 123123123
	country: AF
	emalUP: false
	dummy: 1602866624428
}
{
	request_type: update-password
	passWD: 1234
	dummy: 1602866721044
}
-check coupon
checkout (NI) POST /v1.0/shield/checkout_payment.php?pay=on
{
	coupon:"fsdfsfsf"
	fldCardNumber: 1234123412341234
	fldExpirationYear: 2023
	fldExpirationMon: 05
	fldCardCVV: 435
	gopay: pay
	prods: |bd955b5c-165e-fef7-0000-596639a57b62|,|321be506-af21-38b9-e98b-5966365a010d|
	fldShipFirstName:
	fldShipLastName:
	fldShipEmail:
	fldShipPhone:
	fldShipAddress1:
	fldShipAddress2:
	fldShipCity:
	fldShipStateProvince:
	fldShipCountry:
}
admin login - password
genereate coupon - 5 fields (count,price,expiration,priceLevel,series)
generate activation code - 2 fields (codeCount,codesPerPackage)
invoices - table, 2 fields, checkboxes (name is "invoice:8b7eb9f3-7c85-21f9-a395-36e47d0b8ef6"), (check all), send, mark as sent only


activation code: aaa bbbbb ccc


Qs:
how to get max kids?
how to identify packages on "sale"?
where to save git of existing react code?
how does adding a package to cart work?
4bd28190 has games and doesn't in the different pulls


Latters:
packages have a class, coupons have a class,








*/



