import React from 'react';
import {Link} from "react-router-dom";
import {Config,Lang} from "../../services/Services";
import {ShopBtn} from '../utils/Utils';

const ProductRow=({product,addRemove=null,isCartView=false,game=null,gameSelect=null})=>{
	const btns=()=>{
		if(isCartView){
			return( 
			<>
				<div className="cart-price" dangerouslySetInnerHTML={{__html:(product.price?.formated_final_price || "0.00")}} ></div>
				<ShopBtn addRemove={addRemove} product={product} isCartView />
			</>);
		}

		if(game){
			return <button className="link empty" onClick={gameSelect}  >{Lang.productsCatalog.more_details} &gt;</button>
		}

		return (
		<>
			<Link to={"/product/"+product.id} ><button className="link empty" >{Lang.productsCatalog.more_details} &gt; </button></Link>
			{product.price?.special_price_in_effect && <div className="old-price" dangerouslySetInnerHTML={{__html:product.price.formated_price}} ></div>}
			<ShopBtn product={product} addRemove={addRemove} />
		</>);
	}

	return(

		<div className="product">
			<div className="ages" >{Lang.productsCatalog.icon_side_title_age}: {product.ages}</div>
			<img alt="" className="image" src={Config.server_url+"images/"+(game?game.icon:product.image_filename)} />
		
			<div className="info" >
				<div>
					<h5>{(game?game?.display_name:product?.text?.name)}</h5>
					{!isCartView && 
					<p dangerouslySetInnerHTML={{__html:(game?game?.description:product?.text?.description)}}></p>
					}
				</div>
				<div className="btns">
					{btns()}
				{/* <button className="link empty " >Info</button>
					<button className="link checked" >price</button> */}
				</div>
			</div>
		</div>
	)

}

export default ProductRow;
