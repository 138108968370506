import React, { useEffect,useContext,useState  } from 'react';
import { useHistory } from 'react-router-dom';
import { Api } from '../../services/Services';

const MustAdminPass=(Component)=>{
	const RetComponent=(props)=>{
		const history=useHistory();
		if(!Api.adminPassword){setTimeout(()=>history.push("/admin/login"));return null;}	
		return <Component {...props} />
	}
	return RetComponent;
}

export default MustAdminPass;