import React from 'react';
import {FormService} from "../../services/Services";

const SelectGen=({fieldChange=FormService.fieldChangeHooks,fieldObj,value,error,tabIndex=0})=>{
	//fieldObj,error,value,fieldChange
	
	if(value===undefined){
		value=fieldObj.value;
	}
	const errCls=(error?"error":"");
	return(
		<select name={fieldObj.name} className={"form-control "+errCls}  onChange={(e)=>fieldChange(fieldObj,e.target.value)} defaultValue={value || 0} tabIndex={tabIndex} >
			{fieldObj.opts.map(it=><option key={it.key} value={it.key} >{it.value}</option>)}
		</select>
	)	
}

export default SelectGen;
