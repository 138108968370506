import React from 'react';
import {Error} from "./Utils";

const RegiBtns = ({servError="",children}) => {
	
	return (
		<div className="regi-btns">
			<Error err={servError} isBtn />
			<div>
				{children}
			</div>
		</div>
	)

}
export default RegiBtns;