import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false,error:null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
		return { hasError: true,error };
		// this.setState({hasError:true});
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
		// logErrorToMyService(error, errorInfo);
		// console.
		console.error(error,errorInfo);
		this.setState({hasError:true});
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return( 
				<div className="error-bounrady" >
					{/* <div style="display:flex;flex-direction: column; justify-content: center;align-items: center; height:100vh;font-size: 40px;" >
						<img src="https://il.webee-world.com/v1.0/images/logo-games.png" />
						<div>
							You need to enable JavaScript to run this app
						</div>
						<div style="font-size: 30px;;" >
							<a target="_blank" href="https://www.enable-javascript.com/" >For explanation click here</a>
						</div>

					</div> */}

					<h1>Sorry, something went wrong...</h1>
					<p>
						Please reload the page and email us at <a href="mailto:info@webee-world.com">info@webee-world.com</a> if you need more assistance.
					</p>
				</div>
			);
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;