import React,{useState,useEffect} from 'react';
import {ModalService} from "../../services/Services";
import Toast from "./Toast";

const ToastContainer=()=>{
	let [toasts,toastsUpd]=useState([]);
	// console.log("start",toasts);
	let curId=1;
	useEffect(()=>{
		const obser=ModalService.toastSubject.subscribe(val=>{
			let dims=getToastDims();
			let newToast={id:curId,text:val,...dims};
			toastsUpd([...toasts,newToast]);
			setTimeout(()=>{remove(newToast);},3000);
			curId++;
		});
		return ()=>{obser.unsubscribe();}
	},[])

	const getLowestAvailHeight=()=>{
		let cur=0;
		let heights=toasts.reduce((acc,it)=>{
			acc.push(it.h);return acc;
		},[]);
		while(true){
			if(heights.includes(cur)){
				cur++;
			}
			else{
				return cur;
			}
		}
	}
	const remove=obj=>{
		console.log(obj);
		console.log(toasts);
		toasts=[...toasts.filter(it=>it.id!==obj.id)];
		toastsUpd(toasts);	
	}
	const getToastDims=()=>{
		let baseBot=20;
		let eachBot=60;
		let h=getLowestAvailHeight();
		let toastsInColumn=Math.floor((window.innerHeight-baseBot)/eachBot);
		let row=Math.floor(h/toastsInColumn);
		console.log(h,toastsInColumn,row);
		let postH=h%toastsInColumn;
			
		let right=40+(row)*270; 
		let bottom=baseBot+(postH)*eachBot;

		return {h,right,bottom}
	}


	return(	//onClick={()=>remove(obj)}
		<>
			{toasts.map(obj=><Toast  key={obj.id} obj={obj} />)} 
			<div></div>
		</>
	)
	
}

export default ToastContainer;