import React from 'react';

const TopNotif=({children,isNotif=false,className=""})=>{
	const topClass="top-notif "+(className?" "+className:"");	//"+(isNotif?"top-notif":"")
	return (
		<div className={topClass}>
			{children}
		</div>
	)
}
export default TopNotif;
