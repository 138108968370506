import React, { useEffect, useState} from 'react';
import {  useParams } from "react-router-dom";
import { Api, server } from "../services/Services";

const StaticPage = () => {
	const [pageObj, pageObjUpd] = useState(null);
	const { page } = useParams();

	const load = async () => {
		let obj = await Api.getCmsPage(page);
		if (obj && obj.page) {
			obj.page.content = obj.page.content.replace(/src="/ig, "src=\"" + server);
			let pageObj = obj.page;
			pageObjUpd(pageObj);
		}
	}

	useEffect(() => {
		load();
	}, [page]);

	if (!pageObj) { return null; }
	return (
		<section className="static pad">
			<h1>{pageObj.header}</h1>
			<div className="content">
				<p dangerouslySetInnerHTML={{__html:pageObj.content}}></p>
			</div>
		</section>
	)
}

export default StaticPage;
