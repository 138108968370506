import React from 'react';

const Stars = ({obj}) => {
	const getStars=rating=>{
		rating=rating >= 0.8?3:Math.round(rating*3);
		let arr=[];
		for(let i=0;i<rating;i++){
			arr.push(1);
		}
		for(let i=rating;i<3;i++){
			arr.push(0);
		}
		return arr.map((it,ind)=><img key={ind} alt="" src={"/assets/images/star"+(it?it:"")+".png"} /> );
	}

	return (
		<div className="stars-cont">
			{[0,1].map(num=>
			<ul key={num} >
				{obj.skills.map((skill,index)=>
					(index%2===num?<li key={index} >{getStars(skill.factor)}<span>{skill.name}</span></li>:"" )
				)}  
			</ul>
				)}
			
		</div>
	)
}
export default Stars;