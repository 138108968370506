import React, { useContext } from 'react';
import { ModalService,Lang } from "../../services/Services";
import LoginScreen from '../user/LoginScreen';
import AppContext from '../../AppContext';

const ShopBtn = ({product,respClass="link-sm",addRemove,isCartView,isChartsView}) => {
	respClass+=" shop-btn ";
	const context=useContext(AppContext);
	const StoreService = context.StoreService;
	const isInCart=StoreService.productIdsInCart.includes(product.id);
	const user=context.UserService.user;
	const checked=(isInCart?" checked":"");
	const inAccount=(user && user.gameIds && StoreService.userAndProductShareGames(user,product));

	const addRemoveFunc=()=>{
		StoreService.addRemoveFromCart(product.id,!isInCart);
		if(addRemove){
			addRemove(product);
		}
	}


	const getPricedBtnInner=()=>{
		let postText=(isChartsView?Lang.scoring_charts["reco-buy-now"]:Lang.productsCatalog[user?"add_to_cart":"need_account"]);
		return {__html:(product.price?.formated_final_price || "0.00")+" &nbsp; "+postText};
	}

	if(isCartView){
		return <button className="link empty " onClick={addRemoveFunc} >{Lang.productsCatalog.remove_from_cart}</button>;
	}

	if(!user){
		return <button className="link"  dangerouslySetInnerHTML={getPricedBtnInner()} disabled  ></button>;
	}

	return (inAccount?
		<button className="link" >{Lang.productsCatalog.in_account}</button>
		:
		<button className={" link"+checked} onClick={addRemoveFunc} dangerouslySetInnerHTML={getPricedBtnInner()}   ></button>
	)
}
export default ShopBtn;