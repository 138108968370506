import React,{useState,useEffect} from 'react';


const IconSelect=props=>{
	const [val,valUpd]=useState(null);
	const [isMale,isMaleUpd]=useState(null);
	useEffect(()=>{
		let obj={isMale:props.gender==="male",val:Number(props.val)};
		if(obj.isMale!==isMale || obj.val!==val){
			valUpd(obj.val);
			isMaleUpd(obj.isMale);
		}
	})

	const chVal=v=>{
		let newVal=val+v;
		if(newVal<0){newVal=20;}
		else if(newVal>20){newVal=0;}
		valUpd(newVal)
		props.onChange(newVal);
	}



	const x=(isMale?0:-30);
	const y=(val*-30);
	if(props.imgOnly){
		return <div className="icon_select_img"  style={{backgroundPosition:`${x}px ${y}px`}} ></div>
	}
	return (
		<div className="icon_select_box" style={{display:"inline-block"}} >
			<div className="icon_select_img"  style={{backgroundPosition:`${x}px ${y}px`}} ></div>
			<div className="icon_select_btn_box">
				<div className="icon_select_btn icon_select_btn_up" onClick={()=>chVal(1)}   ></div>
				<div className="icon_select_btn icon_select_btn_down" onClick={()=>chVal(-1)} ></div>
			</div>
		</div>		
	)
	
}


export default IconSelect;