import React from 'react';


const CircChart=({perc,children})=>{
	return (
		<div className="circChart" >
			<div   >{children}</div>
			<svg  viewBox="0 0 32 32">
				<circle   strokeDasharray={(perc || 0)+" 100"}  r="16" cx="16" cy="16" />
			</svg>
		</div>
	)
}
export default CircChart;