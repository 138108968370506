import React, { useContext, useState } from 'react';
import AppContext from "../../AppContext";
import KidRow from "./KidRow";
import { BaseSubmit, InputDiv, BaseBtn, Error, RegiWrap,RegiBtns } from "../utils/Utils";
import { FormService, ModalService, Api,Lang,Config } from "../../services/Services";
import {newKidRow} from "../Funcs";


const Settings = () => {
	const tabs = [Lang.my_account["tab-children"], Lang.my_account["tab-contact"], Lang.my_account["tab-password"]];
	const context = useContext(AppContext);
	const [user, userUpd] = useState(context.UserService.user);
	const maxKids=user.max_kids || Config.maxKids;
	const [tab, tabUpd] = useState(0);
	const [errors, errorsUpd] = useState(0);
	const [servError,servErrorUpd]=useState(null);

	const fieldsObj = {};
	fieldsObj.email = useState(user.email);
	fieldsObj.password = useState("");
	fieldsObj.passwordRepeat = useState("");
	fieldsObj.phone = useState(user.phone);
	fieldsObj.country = useState(user.country);
	fieldsObj.wantsUpdate = useState(user.wantsUpdate);

	const fields = {
		1: [
			{ name: "email", label: "Email", placeholder: Lang.my_account.label_email, validation: { isEmail: true, notEmpty: true } ,toLowerCase:true},
			{ name: "phone", label: "Phone", placeholder: Lang.my_account.label_contact_phone, validation: { notEmpty: true } },
			{ name: "country", label: Lang.my_account.label_country, type: "select", opts: Lang.country_codes, validation: { notEmpty: true } },
			{ name: "wantsUpdate", label: Lang.my_account.label_email_updates, type: "checkbox", validation: {} },
		],
		2: [
			{ name: "password", label: "Password", placeholder: Lang.my_account.label_choose_password, type: "password", validation: { notEmpty: true,minLength:4 } },
			{ name: "passwordRepeat", label: "Repeat Password", placeholder: Lang.my_account.label_renter_password, type: "password", validation: { notEmpty: true,minLength:4 } },
		]
	};
	Object.keys(fields).forEach(num => FormService.fieldsHoist(fields[num], fieldsObj));


	const moveTab = tab => {
		tabUpd(tab);
		errorsUpd({});
	}
	const addKid = () => {
		console.log("hi",user.kids.length, maxKids);
		if (user.kids.length < maxKids) {
			console.log("hi",user.kids.length, maxKids);
			let newUser={...user,kids:[...user.kids,newKidRow()]};
			userUpd(newUser);
		}
		
	}
	const close = () => {
		ModalService.closeBlueCover();
	}
	const submit = async () => {
		let errors = {};
		let res = {};
		switch (tab) {
			case 0:	//children
				let error = "";
				for (let kid of user.kids) {
					let err = FormService.validate({ notEmpty: true }, kid.name);
					if (err) {
						error = Lang.errorTexts.all_info;
						break;
					}
					err = FormService.validate({ notEmpty: true }, kid.birthday);
					if (err) {
						error = Lang.errorTexts.all_info;
						break;
					}
				}
				errorsUpd({ kids: error });
				if (!error) {
					let sendObj = user.kids.reduce((acc, kid, ind) => {
						acc[`data[${ind}][name]`] = kid.name;
						acc[`data[${ind}][dob]`] = kid.birthday;
						acc[`data[${ind}][gender]`] = kid.gender;
						acc[`data[${ind}][img_idx]`] = kid.icon;
						acc[`data[${ind}][cid]`] = kid.id;
						acc[`data[${ind}][rowid]`] = ind;
						return acc;
					}, {});
					servErrorUpd(null);
					res = await Api.updateChildren(context.UserService, sendObj);
					if (res.success) {
						let updObj = { kids: user.kids };
						context.UserService.updUserInfo(updObj);
						ModalService.openToast(Lang.errorTexts.save_suc);
					}
					else{
						servErrorUpd(Lang.errorTexts.save_fail);
					}

				}
				break;
			case 1:	//details
				if (!FormService.validateEntireFormHooks(fields[tab], errorsUpd)) {
					let sendObj = {
						userID: fieldsObj.email[0],
						phoneNU: fieldsObj.phone[0],
						country: fieldsObj.country[0],
						emalUP: fieldsObj.wantsUpdate[0]
					};
					servErrorUpd(null);
					res = await Api.updateAccount(context.UserService, sendObj);
					if (res.success) {
						let updObj = fields[1].reduce((acc, field) => { acc[field.name] = field.value; return acc }, {});
						context.UserService.updUserInfo(updObj);
						ModalService.openToast(Lang.errorTexts.save_suc);
					}
					else{
						servErrorUpd(Lang.errorTexts.save_fail);
					}
				}
				break;
			case 2:	//password
				errors = FormService.validateEntireFormHooks(fields[tab], errorsUpd, true)
				if (!FormService.hasErrors(errors)) {
					if (fieldsObj.password[0] !== fieldsObj.passwordRepeat[0]) {
						errors.passwordRepeat = Lang.errorTexts.passwords_same;
						errorsUpd(errors);
					}
					else {
						servErrorUpd(null);
						res = await Api.updatePassword(context.UserService, fieldsObj.password[0]);
						if (res.success) {
							context.UserService.updUserInfo({ password: fieldsObj.password[0] });
							ModalService.openToast(Lang.errorTexts.save_suc);
						}
						else{
							servErrorUpd(Lang.errorTexts.save_fail);
						}

					}
				}
				break;
			default:
				break;
		}
		//these are 3 separate requests!!
	}

	const kidRowChangeField = (row, field, value) => {
		row[field] = value;
		console.log(user.kids);
		userUpd({ ...user });
	}


	if (!user) { return null; }
	return (
		<>
		<RegiWrap tabs={
			tabs.map((it, key) => <li key={key} onClick={() => moveTab(key)} className={(key === tab ? "active" : "")}  >{it}</li>)
		}>
			<ul className="form">
				{tab === 0 &&
					<>
						{user.kids.map((kid, k) => <KidRow key={k} row={kid} onChange={kidRowChangeField} />)}
						{errors.kids &&
							<Error isBtn err={errors.kids} />
						}
						<button onClick={addKid} disabled={user.kids.length >= maxKids} className="btn add-kid" >+ {Lang.my_account["button-add-child"]}</button>
					</>
				}

				{tab === 1 &&
					fields[tab].map(fieldObj => <li key={fieldObj.name}><InputDiv fieldObj={fieldObj} error={errors[fieldObj.name]} /></li>)
				}

				{tab === 2 &&
					<>
						<li>
							<h6>{Lang.my_account["tab-password"]}</h6>
						</li>
						{fields[tab].map(fieldObj => <li key={fieldObj.name}><InputDiv fieldObj={fieldObj} error={errors[fieldObj.name]} /></li>)}
					</>

				}
			</ul>

			<RegiBtns servError={servError} >
				<BaseSubmit onClick={submit} label={Lang.my_account["button-save"]} />
				<BaseBtn onClick={close} label={Lang.my_account["button-cancel"]} className="btn-danger" />
			</RegiBtns>	
		</RegiWrap>
		</>
	);

}

export default Settings;
