import React,{useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { compose, GenForm, MustAdminPass } from '../Hocs/Hocs';
import {FormService,Api,ModalService, Lang} from "./../../services/Services";
import {BaseSubmit,BaseWrap,InputDiv,Error, RegiBtns} from "./../utils/Utils";
import AdminMenu from "./AdminMenu";


const fieldsObj={};
const fields=[
	{name:"from",validation:{notEmpty:true,numberPositive:true},label:"Package From",placeholder:"From"},
	{name:"to",validation:{numberPositive:true},label:"Package To",placeholder:"To"},
	{name:"packageCode",type:"select",opts:[],validation:{notZero:true},label:"Activation Package Code"},
];
const apiFunc=async()=>{
	let sendObj=fields.reduce((acc,it)=>({...acc,[it.name]:it.value}),{});
	return await Api.updCodePackages(sendObj);
}

const UpdPackages=({errors,submit,servError})=>{
	fieldsObj.from=useState("");
	fieldsObj.to=useState("");
	fieldsObj.packageCode=useState(0);
	FormService.fieldsHoist(fields,fieldsObj);
	useEffect(()=>{
		const load=async ()=>{
			const ret=await Api.getCodePackages();
			// const ret={
			// 	packageCodes:[
			// 		{key:"bbb",value:"bbb"},
			// 		{key:"aaa",value:"aaa"},
			// 	]
			// };
			if(ret.packages){
				// console.log(ret.packages.map(it=>({key:it,value:it})));
				fields[2].opts=ret.packages.map(it=>({key:it,value:it}));
				fieldsObj.packageCode[1](ret.packages.length?ret.packages[0]:0);
			}
		}
		load();
	},[]);

	return (
		<BaseWrap items={<AdminMenu  />}  >
			<div style={{width:"50%"}}>
				<h1>Update Package Codes</h1>
				
				<ul className="form "  >
					{
						fields.map(fieldObj=><li key={fieldObj.name}><InputDiv doLabel  fieldObj={fieldObj}  error={errors[fieldObj.name]}  /></li>)
					}
				</ul>
				<RegiBtns servError={servError} >
					<BaseSubmit onClick={submit}  />
				</RegiBtns>
			</div>
		</BaseWrap>
	);
}

export default GenForm(compose(MustAdminPass,UpdPackages),{fields,apiFunc});