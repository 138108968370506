import React,{useState,useRef} from 'react';
import { useHistory } from 'react-router-dom';
import { GenForm } from '../Hocs/Hocs';
import {FormService,Api,ModalService, Lang} from "./../../services/Services";
import {BaseSubmit,BaseWrap,CircChart,InputDiv,RegiBtns} from "./../utils/Utils";



const fields=[
	{name:"password",type:"password",placeholderLang:["activate_login","label_password"],labelLang:["activate_login","label_password"],validation:{notEmpty:true}},
];
const fieldsObj={};
const apiFunc=async ()=>await Api.adminLogin(fieldsObj.password[0]);

const AdminLogin=({errors,submit,servError})=>{
	fieldsObj.password=useState("");
	const history=useHistory();
	FormService.fieldsHoist(fields,fieldsObj);
	
	const submitFunc=async ()=>{
		if(await submit()){
			Api.adminPassword=fieldsObj.password[0];
				history.push("/admin/lang");
		}
	}
		
	return (
		<BaseWrap  >
			<div style={{width:"50%"}}>
				<h1>Admin Login</h1>
				<ul className="form " >
					{
						fields.map(fieldObj=><li key={fieldObj.name}><InputDiv doLabel fieldObj={fieldObj}  error={errors[fieldObj.name]}  /></li>)
					}
				</ul>
				<RegiBtns servError={servError} >
					<BaseSubmit onClick={submitFunc}  />
				</RegiBtns>
		
				{/* <MatInp label="מספר טלפון" /> */}
				{/* <CircChart perc={90}  ><h1>Hi</h1></CircChart> */}
				{/* <Tooltip tooltip="some text..." >
					<h1 style={{display:"inline-block"}}>gggfg....</h1>
				</Tooltip> */}
				{/* <MouseCircleTry /> */}
			</div>
		</BaseWrap>
	);
}

const MouseCircleTry=()=>{
	const left=200;
	const top=200;
	// let curId=1;
	// const [point,pointUpd]=useState(null);
	const onClick=ev=>{
		let x=ev.clientX-left;
		let y=ev.clientY+document.documentElement.scrollTop-top;
		let cont=document.getElementById("cont");
		let div=document.createElement("DIV");
		div.className="expand";
		div.style.left=x+"px";
		div.style.top=y+"px";
		cont.appendChild(div);
		setTimeout(()=>div.parentElement.removeChild(div),500);
	}
	return (
		<div id="cont" onClick={onClick} style={{border:"1px black solid",width:"200px",height:"200px",position:"absolute",left:left+"px",top:top+"px",background:"white"}}></div>
	) 
}


const Tooltip=props=>{
	return (
		<>
			<div className="drag-hint"  >
				<div  className="popup">
					<span >{props.tooltip}</span>
				</div>
				{props.children}
			</div>
		</>
	)
}

const MatInp=props=>{
	const [value,chValue]=useState(props.value || "");
	const inputEl=useRef(null);
	return (
		<div className={"MatInp "+(value.length?"hasValue":"")} >
			<label onClick={()=>inputEl.current.focus()} >{props.label || ""}</label>
			<input ref={inputEl} onChange={e=>chValue(e.target.value)} />
		</div>
	)
}



export default GenForm(AdminLogin,{fields,apiFunc,langFail:["errorTexts","login_fail"],langSuc:["errorTexts","login_suc"]});
