import Api from "./Api";

class StoreService{
	products=[];
	productIdsInCart=[];
	curCoupon={code:"",discount:0};

	constructor(){
		let cart=localStorage.getItem("cart");
		if(cart){
			this.productIdsInCart=JSON.parse(cart);
			// console.log("productIdsInCart JSON",[...this.productIdsInCart]);
		}
	}
	
	async getProductsFromApi(){
		if(!this.hasProducts()){
			let res=await Api.getAllPackages();
			if(res.packages){
				this.products=res.packages;
				this.products.forEach(this.crAgeCroups);
				// console.log(this.products);
			}
			return true;
		}
		return false;
	}
	hasProducts(){
		return !!this.products.length;
	}
	crAgeCroups(prod){
		prod.ageGroups=prod.age_group.split(",").filter(it=>it.length).map(it=>Number(it.trim().replace(/\^/g,"")));
		let lowestGroup=Math.min(...prod.ageGroups);
		let highestGroup=Math.max(...prod.ageGroups);
		let highest=highestGroup===3?6:highestGroup+1;
		prod.ages=`${lowestGroup}-${highest}`;
		// console.log(prod.ages);
	}
	resetProds(){
		this.products=[];
	}

	resetCoupon(){
		this.updateCoupon("",0);
	}
	updateCoupon(code,discount){
		this.curCoupon.code=code;
		this.curCoupon.discount=+discount;
	}

	resetCart(){
		this.productIdsInCart=[];
		this.updateCartStorage();
	}
	updateCartStorage(){
		localStorage.setItem("cart",JSON.stringify(this.productIdsInCart));
	}
	getProduct(id){
		return this.products.find(it=>it.id===id);
	}
	getProductsByFilter(filter){
		return (filter?
			[...this.products.filter(it=>it.ageGroups.includes(filter))]
			:[...this.products])
	}
	getProductsInCart(){
		console.log("getProductsInCart");
		return this.getProductsByIds(this.productIdsInCart);
	}
	getCartCost(){
		return this.getCostByIds(this.productIdsInCart);
	}
	addRemoveFromCart(id,isAdd){
		let productIdsInCart = [...this.productIdsInCart.filter(it =>it!==id)];
		if (isAdd) {
			productIdsInCart.push(id);
		}
		
		this.productIdsInCart=[...productIdsInCart];
		this.updateCartStorage();
	}
	getCartIdsForCheckout(){
		return this.productIdsInCart.map(id=>`|${id}|`).join(",");
	}
	getProductsByIds(arrIds){
		return [...this.products.filter(it=>arrIds.includes(it.id))];
	}
	getGameIdsByProductIds(prodIds){
		return this.products.filter(it=>prodIds.includes(it.id)).reduce((acc,prod)=>[...acc,...prod.games],[]);
	}
	getCartGames(){
		return this.getGameIdsByProductIds(this.productIdsInCart);
	}
	tryAddProdData(prod){
		let item=this.products.find(it=>it.id==prod.id);
		if(item){
			Object.keys(item).forEach(key=>prod[key]=item[key]);
		}
	}
	getCostByIds(arrIds,avoidDiscount=false){
		// console.log(arrIds);
		let sum=this.products.filter(it=>arrIds.includes(it.id)).reduce((acc,it)=>acc+(it.price?.final_price || 0),0) || 0;
		if(!avoidDiscount && this.curCoupon && this.curCoupon.discount){
			sum-=this.curCoupon.discount;
		}
		return Math.max(sum,0);
	}
	userAndProductShareGames(user,product){
		// console.log("h",product.games);
		if(!product || !product.games){return false}
		for(let game of product.games){
			let gameId=(typeof game==="string"?game:game.game);
			// console.log(game);
			if(user.gameIds.includes(gameId)){
				return true;
			}
		}
		return false;
	}
}



export default StoreService;