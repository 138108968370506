import React from 'react';
import { Link } from "react-router-dom";

const AdminMenu=()=>{
	const chLink=window.location.pathname.split("/").pop();
	const items=[
		{link:"cr-coupons",name:"Coupon"},
		{link:"cr-activation-codes",name:"Activation Code"},
		{link:"invoices",name:"Invoices"},
		{link:"upd-packages",name:"Update Packages"},
		{link:"lang",name:"Lang"},
	]
	return (
		items.map(it=><button key={it.link} className={"btn "+(chLink===it.link?"active":"")} ><Link to={"/admin/"+it.link}  >{it.name}</Link></button>)
	);
}

export default AdminMenu;