import React from 'react';


const BaseBtn=({onClick,className="",width=20,label,disabled})=>{

	return (
		<button  onClick={onClick} disabled={disabled}  className={"btn "+className}  >{label}</button>
	)	//style={{width: (width || 20)+"%"}}
}


export default BaseBtn;