import React, { useState  } from 'react';
import { FormService,ModalService,Lang } from '../../services/Services';

const GenForm=(Component,{fields,langFail=["errorTexts","save_fail"],langSuc=["errorTexts","save_suc"],apiFunc})=>{
	const RetComponent=(props)=>{
		const [servError,servErrorUpd]=useState(null);
		const [errors,errorsUpd]=useState({});

		const getLang=(arr)=>{
			return Lang[arr[0]][arr[1]];
		}
		
		const submit=async(arg)=>{
			if(!FormService.validateEntireFormHooks(fields,errorsUpd)){
				servErrorUpd(null);
				let res=await apiFunc(arg);
				if(res.success){
					ModalService.openToast(getLang(langSuc));
					return res;
				}
				else{
					servErrorUpd(getLang(langFail));
				}
			}
			return false;
		}
		return <Component {...props} errors={errors} submit={submit} servError={servError} />;
	}
	return RetComponent;
}



export default GenForm;





