import React, { useContext, useState } from 'react';
import Login from "./Login";
import Activation from "./Activation";
import AppContext from "../../AppContext";
import { RegiWrap } from "../utils/Utils";
import { Lang} from "../../services/Services";

const LoginScreen = (props) => {
	const context = useContext(AppContext);
	const [isLogin, isLoginUpd] = useState(false);
	if (context.UserService.user) { return null; }
	// console.log(isLogin);

	return (
		<RegiWrap hasImg tabs={
			<>
			<li className={!isLogin ? "active" : ""} onClick={() => isLoginUpd(false)} >{Lang.activate_login.butten_register}</li>
			<li className={isLogin ? "active" : ""} onClick={() => isLoginUpd(true)}  >{Lang.activate_login.butten_login}</li>
			</>
		}		>
			{isLogin ? <Login /> : <Activation />}
		</RegiWrap>	
	);

}

export default LoginScreen;
