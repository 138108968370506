import React,{useState} from 'react';
import { useHistory } from 'react-router-dom';
import { compose, GenForm, MustAdminPass } from '../Hocs/Hocs';
import {FormService,Api,ModalService, Lang} from "./../../services/Services";
import {BaseSubmit,BaseWrap,InputDiv,RegiBtns} from "./../utils/Utils";
import AdminMenu from "./AdminMenu";

const priceLevels=[
	{key:"X",value:"None"},
	{key:"A",value:"Class A"},
	{key:"B",value:"Class B"},
	{key:"C",value:"Class C"},
	{key:"D",value:"Class D"},
	{key:"E",value:"Class E"},
];
const fieldsObj={};
const fields=[
	//count,price,expiration,priceLevel,series
	{name:"count",label:"Count",placeholder:"Enter...",validation:{notEmpty:true,numberPositive:true}},
	{name:"price",label:"Price",placeholder:"Enter...",validation:{notEmpty:true,numberPositive:true}},
	{name:"expiration",label:"Expiration",placeholder:"Enter...",type:"date",validation:{notEmpty:true}},
	{name:"priceLevel",label:"Price Level",type:"select",opts:priceLevels,validation:{notZero:true}},
	{name:"series",label:"Series",placeholder:"Enter...",validation:{notEmpty:true}},
];

const apiFunc=async()=>{
	let sendObj=fields.reduce((acc,it)=>({...acc,[it.name]:it.value}),{});
	return await Api.crCoupon(sendObj);
}

const CrCoupons=({errors,submit,servError})=>{
	fieldsObj.count=useState("1");
	fieldsObj.price=useState("1");
	fieldsObj.expiration=useState(FormService.dateToStr(new Date()));
	fieldsObj.priceLevel=useState("X");
	fieldsObj.series=useState("");
	FormService.fieldsHoist(fields,fieldsObj);

	
	return (
		<BaseWrap items={<AdminMenu  />}  >
			<div style={{width:"50%"}}>
				<h1>Create Coupons</h1>
				<h4>It is NOT RECOMMENDAED to request more than 500 codes at once</h4>
				
				<ul className="form" >
					{
						fields.map(fieldObj=><li key={fieldObj.name}><InputDiv  doLabel fieldObj={fieldObj}  error={errors[fieldObj.name]}  /></li>)
					}
				</ul>
				<RegiBtns servError={servError} >
					<BaseSubmit onClick={submit}  />
				</RegiBtns>
		
			</div>
		</BaseWrap>
	);
}

export default GenForm(compose(MustAdminPass,CrCoupons),{fields,apiFunc});