import React, { useState, useContext, useEffect } from 'react';
import KidRow from "./KidRow";
import AppContext from "../../AppContext";
import { InputDiv, Error, BaseBtn, BaseSubmit,RegiWrap,RegiBtns } from "../utils/Utils"
import { FormService, Api, ModalService,Lang, Config } from "../../services/Services";
import {newKidRow} from "../Funcs";



const Regi = () => {
	const tabs = [Lang.activate1.step_name_1,Lang.activate1.step_name_2, Lang.activate1.step_name_3];
	const context = useContext(AppContext);
	const maxKids = Config.maxKids;
	const [kids, kidsUpd] = useState([]);
	const [tab, tabUpd] = useState(0);
	const [errors, errorsUpd] = useState(0);
	const [servError,servErrorUpd]=useState(null);

	console.log(context.UserService.isGoToPlay);

	const fieldsObj = {};
	fieldsObj.email = useState("");
	fieldsObj.password = useState("");
	fieldsObj.passwordRepeat = useState("");
	fieldsObj.phone = useState("");
	fieldsObj.country = useState(Config.default_country_code);
	fieldsObj.wantsUpdate = useState(true);


	const fields = {
		0: [
			{ name: "email", label: "Email", placeholder: Lang.activate1.label_email, validation: { isEmail: true, notEmpty: true } ,toLowerCase:true},
			{ name: "password", label: "Password", placeholder: Lang.activate1.label_choose_password, type: "password", validation: { notEmpty: true,minLength:4 } },
			{ name: "passwordRepeat", label: "Repeat Password", placeholder: Lang.activate1.label_renter_password, type: "password", validation: { notEmpty: true,minLength:4 } },

		],
		1: [
			{ name: "phone", label: "Phone", placeholder: Lang.activate1.label_contact_phone, validation: { notEmpty: true } },
			{ name: "country", label: "Country", type: "select", opts: Lang.country_codes, validation: { notEmpty: true } },
			{ name: "wantsUpdate", label: Lang.activate1.label_email_updates, type: "checkbox", validation: {} },
		]
	};
	
	
	Object.keys(fields).forEach(num => FormService.fieldsHoist(fields[num], fieldsObj));

	const moveTab = tab => {
		tabUpd(tab);
	}

	useEffect(() => {
		addKid();
	}, [])

	const addKid = () => {
		let kidsTmp = [...kids];
		if (kidsTmp.length < maxKids) {
			kidsTmp.push(newKidRow());
			kidsUpd(kidsTmp);
		}
		
	}
	const submit = async () => {
		let errors = {};
		let errTab = -1;

		errors = { ...FormService.validateEntireFormHooks(fields[0], errorsUpd, true)};
		if (fieldsObj.password[0] !== fieldsObj.passwordRepeat[0]) {
			errors.passwordRepeat = Lang.errorTexts.passwords_same;
		}
		if (FormService.hasErrors(errors)) { errTab = 0; }
		errors={...errors, ...FormService.validateEntireFormHooks(fields[1], errorsUpd, true) }
		if (errTab===-1 && FormService.hasErrors(errors)) { errTab = 1; }

		let kidsError = "";
		for (let kid of kids) {
			let err = FormService.validate({ notEmpty: true }, kid.name);
			if (err) {
				kidsError = Lang.errorTexts.all_info;
				break;
			}
			err = FormService.validate({ notEmpty: true }, kid.birthday);
			if (err) {
				kidsError = Lang.errorTexts.all_info;
				break;
			}
		}
		errors.kids = kidsError;
		if (errTab === -1 && FormService.hasErrors(errors)) { errTab = 2; }

		errorsUpd(errors);

		if (!FormService.hasErrors(errors)) {
			let sendObj = {
				userID: fieldsObj.email[0],
				phoneNU: fieldsObj.phone[0],
				country: fieldsObj.country[0],
				emalUP: fieldsObj.wantsUpdate[0],
				create: true,
				activetionKey: context.UserService.activationCode,
				passWD: fieldsObj.password[0],
			};
			kids.reduce((acc, kid, ind) => {
				acc[`childName[${ind}]`] = kid.name;
				acc[`birthDate[${ind}]`] = kid.birthday;
				acc[`gender[${ind}]`] = kid.gender;
				acc[`img-index[${ind}]`] = kid.icon;
				return acc;
			}, sendObj);
			servErrorUpd(null);
			let res = await Api.regi(sendObj);
			if (res.success) {
				ModalService.openToast(Lang.errorTexts.regi_suc);

				//from here!!!!
				let res=await Api.login(fieldsObj.email[0],fieldsObj.password[0]);
				if(res && res.account){
					context.UserService.updateInfo({user:res.account,curPlayerId:res.playerId});
					ModalService.closeBlueCover();
					if(context.UserService.isGoToPlay){
						context.UserService.goToGames();
					}
				}
			}
			else{
				servErrorUpd(Lang.errorTexts.regi_fail);
			}
		}
		else {
			moveTab(errTab);
		}
	}

	const progress = dir => {
		tabUpd(tab + dir);
	}
	const kidRowChangeField = (row, field, value) => {
		row[field] = value;
		console.log(row, kids);
		kidsUpd([...kids]);
	}

	if (context.UserService.user) { return null; }
	return (
		<RegiWrap hasImg tabs={
			tabs.map((it, key) => <li key={key} onClick={() => moveTab(key)} className={(key === tab ? "active" : "")}  >{it}</li>)
		}>
			<ul className="form">
				{tab === 0 &&
					fields[tab].map(fieldObj => <li key={fieldObj.name}><InputDiv fieldObj={fieldObj} error={errors[fieldObj.name]} /></li>)
				}

				{tab === 1 &&

					fields[tab].map(fieldObj => <li key={fieldObj.name}><InputDiv fieldObj={fieldObj} error={errors[fieldObj.name]} /></li>)
				}

				{tab === 2 &&
					<>

						{kids.map((kid, k) => <KidRow key={k} row={kid} onChange={kidRowChangeField} />)}
						{errors.kids &&
							<Error isBtn err={errors.kids} />
						}
						<button onClick={addKid} disabled={kids.length >= maxKids} className="btn add-kid" >+ {Lang.my_account["button-add-child"]}</button>
					</>

				}
			</ul>

			<RegiBtns servError={servError} >
				{tab > 0 && <BaseBtn onClick={() => progress(-1)} label={Lang.activate1.butten_back} />}
				{tab < 2 && <BaseBtn onClick={() => progress(1)} label={Lang.activate1.butten_next} />}
				{tab === 2 && <BaseSubmit onClick={() => submit()}  label={Lang.activate1.butten_play} />}
			</RegiBtns>
		</RegiWrap>

	);

}

export default Regi;
