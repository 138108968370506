import React from 'react';

const Toast=({onClick,obj})=>{
	return(	//
		<div className="toast show" onClick={onClick} style={{top:obj.bottom+"px",right:obj.right+"px"}}   >
			{obj.text}
		</div>
	)
	
}

export default Toast;