import React, { useRef, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AppContext from "../AppContext";
import { Api, Lang, Config } from "./../services/Services";
import Filter from "./Filter";
import { compose, LoadProds, MustUser } from './Hocs/Hocs';
import { CircChart, ShopBtn } from "./utils/Utils";



const Charts = () => {
	const tabs = [
		{ name: "skills", text: Lang.scoring_charts.cart_desc_3, hasCheck: true, type: "skills_score", lang: Lang.scoring_charts.tab_name_3 },
		{ name: "accomplishments", text: Lang.scoring_charts.cart_desc_2, type: "games_score", lang: Lang.scoring_charts.tab_name_2 },
		{ name: "games", text: Lang.scoring_charts.cart_desc_1, type: "games_distribution", lang: Lang.scoring_charts.tab_name_1 },
		// { name: "advancement", text: "advancement text...", hasCheck: true, type: "skills_set" },
	];
	const resolutions = [
		// { id: 1, text: "today" },
		{ id: 2, text: Lang.scoring_charts.resolution_name_2 },
		// { id: 3, text: "last 14 days" },
		// { id: 4, text: "last 21 days" },
		{ id: 5, text: Lang.scoring_charts.resolution_name_5 },
		// { id: 6, text: "last 60 days" },
		// { id: 7, text: "last 70 days" },
		{ id: 8, text: Lang.scoring_charts.resolution_name_8 },
	]
	const context = useContext(AppContext);
	const history = useHistory();
	const check = useRef(null);
	const [curTab, curTabUpd] = useState(null);
	const [resolution, resolutionUpd] = useState(8);
	const [dataObj, dataObjUpd] = useState({});
	const [filterOpen, filterOpenUpd] = useState(false);

	useEffect(() => {
		loadData();
	}, [curTab, resolution]);

	const loadData = async (tab = null, res = null) => {
		if (!tab) {
			tab = curTab;
		}
		if (!tab) { return; }
		let checked = ((tab.hasCheck && check?.current?.checked) ? 1 : 0);

		// await Api.getChartData2(context.UserService,"games_data");


		let retObj = await Api.getChartData(context.UserService, tab.type, res || resolution, checked);
		let recs = await loadRecs();
		console.log("recs", [...recs.games]);
		recs.games.forEach(prod => context.StoreService.tryAddProdData(prod));
		console.log("recs", [...recs.games]);
		let items = [];
		if (curTab) {
			switch (curTab.type) {
				case "skills_score":
				case "games_score":
					if (retObj.games) {
						retObj.games.forEach((game, index) => {
							let suc = retObj.success[index];
							let fail = retObj.failure[index];
							let total = suc + fail;
							let percentSuc = Math.round(100 * suc / total);
							let percentFail = Math.round(100 * fail / total);
							let hundDiff = 100 - (percentSuc + percentFail);
							percentFail += hundDiff;
							let skillId = (retObj.skillIds ? retObj.skillIds[index] : null);
							items.push({ name: game, percentFail, percentSuc, skillId });
						})
					}
					break;
				case "games_distribution":
					if (retObj.data) {
						let total = retObj.data.reduce((acc, it) => acc + it.y, 0);
						// console.log(total);
						retObj.data.forEach(gameData => {
							items.push({ name: gameData.name, score: (100 * gameData.y / total).toFixed(2) });
							// console.log(total);
						});
					}
					break;
				default:
					break;

			}
		}
		for (let item of items) {
			if (!recs?.games?.length) { break; }
			if (curTab.type == "skills_score") {
				// console.log("here", item.skillId);
				item.recGame = recs.games.find(g => g.skillId == item.skillId);
			}
			else {
				item.recGame = recs.games.shift();
			}

		}
		if (curTab.type == "skills_score") {
			items.sort((a, b) => !!b.recGame - !!a.recGame);
		}

		// console.log(items);


		dataObjUpd({ items });
	}
	const loadRecs = async () => {
		let isSkill = curTab.hasCheck !== undefined;
		let recs = await Api[isSkill ? "getRecommendSkills" : "getRecommendGames"](context.UserService);
		// console.log(recs);
		// recsUpd(recs);
		return recs;
	}

	const chTab = async tab => {
		curTabUpd(tab);
		dataObjUpd({});
		// loadData(tab);
	}
	const chResolution = res => {
		resolutionUpd(res.id);
		// loadData(curTab, res.id);
		filterOpenUpd(false);
	}
	useEffect(() => {
		let curTab = tabs.find(it => it.type === "skills_score");
		chTab(curTab);
	}, []);

	const addRemove = () => {
		console.log("here");
		history.push("/cart");
	}

	// const gameShopBtn=product=>{
	// 	return <ShopBtn onClick={addRemove} isChartsView product={product} />;

	// 	const isInCart=context.StoreService.productIdsInCart.includes(product.id);
	// 	return <button className="link" onClick={()=>addRemove(product,!isInCart)} > <span dangerouslySetInnerHTML={{__html:product.price}} ></span> {Lang.scoring_charts["reco-buy-now"]} &gt; </button>
	// }

	if (filterOpen) {
		return <Filter closeFilter={() => filterOpenUpd(false)} opts={resolutions} chosen={resolution} chOpt={chResolution} />
	}

	return (
		<section className="charts pad">
			<div className="btns">
				{tabs.map(it => <button key={it.name} className={(it.type === curTab?.type ? "active" : "")} onClick={() => chTab(it)} >{it.lang}</button>)}
				<button className="filter" onClick={() => filterOpenUpd(true)} ></button>
			</div>
			{curTab &&
				<div className="main">
					{/* <p dangerouslySetInnerHTML={{__html:curTab.text}}></p> */}
					<div className="stats">
						<div className="results">
							<h4>{Lang.scoring_charts.graph_results}</h4>

							{dataObj && dataObj.items &&
								dataObj.items.map((item, index) =>
									<div className="result" key={item.name}>
										<CircChart perc={item.percentSuc || item.score}  >
											<p>{item.name}  </p>
											{item.score ?
												<p>{Lang.scoring_charts.graph_popularity}  {item.score}%</p>
												:
												<>
													<p>{Lang.scoring_charts.legent_correct}  {item.percentSuc}%</p>
													<p>{Lang.scoring_charts.legent_mistake}  {item.percentFail}%</p>
												</>
											}
										</CircChart>
									</div>
								)}
						</div>
						<div className="more-games">
							<h4>{Lang.scoring_charts["webbe-recommends"]}</h4>
							{dataObj.items && dataObj.items.map(item =>
								<div key={item.name} className={"game " + (item.recGame ? "has" : "")} >
									{item.recGame && item.recGame.name &&
										<>
											<h4>{item.recGame.name}</h4>
											<p>{Lang.scoring_charts[(curTab.name === "skills" ? "reco-skill" : "reco-games")]} {curTab.type == "skills_score" && item.name}</p>
											<img alt="" src={Config.server_url + "images/" + item.recGame.image_filename} />
											{Config.SHOW_SHOP ?
												<ShopBtn addRemove={addRemove} isChartsView product={item.recGame} /> : ""}
										</>
									}
								</div>
							)}
						</div>
					</div>
				</div>
			}
		</section>
	)

}

export default compose(LoadProds, MustUser, Charts);