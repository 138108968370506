import React, { useEffect,useContext,useState  } from 'react';
import AppContext from "../../AppContext";

const LoadProds=(Component)=>{
	const RetComponent=(props)=>{
		const context=useContext(AppContext);
		const [loaded,loadedUpd] = useState(false);
		
		useEffect(() => {
			const load = async () => {
				await context.StoreService.getProductsFromApi();
				loadedUpd(true);
			}
			load();
		}, [])
		if(!loaded){return null;}
		return <Component {...props} />
	}
	return RetComponent;
}

export default LoadProds;