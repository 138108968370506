import React from 'react';
import { ModalService } from "../../services/Services";

const RegiWrap = ({ tabs, children, hasImg = false }) => {
	// console.log(hasImg);
	return (
		<section className="modal-form">
			<div className="bg-blue ">
				<div className="close" onClick={() => ModalService.closeBlueCover()} ></div>
				<div className="modal">
					<ul className="header">
						{tabs}
					</ul>
					<div className="content">
						{children}
					</div>
				</div>
				{hasImg && <img alt="" src="/assets/images/webee1.png" className="form-webee-img only-lg" />}
			</div>
		</section>
		
	)
}
export default RegiWrap;