import {Config,Api,CookieService} from "./Services";

const RtlLangs=["ar","he"];

class Lang{
	static async loadLang(){
		let res=await Api.getLangForRct();
		if(res.lang){
			for(let [key,value] of Object.entries(res.lang)){
				this[key]=value;
			}
		}
		window.document.title=this.home.title;
		window.document.getElementById("meta-desc").setAttribute("content",this.home.title);

		// console.log("here");
		if(this.country_codes){
			this.country_codes=Object.keys(this.country_codes).map(key=>({key,value:this.country_codes[key]}));
			// console.log([...this.country_codes]);
			this.country_codes.sort((a,b)=>a.value>b.value?1:-1);
		}
		this.global_genders=Object.keys(this.global_genders).map(key=>({key,value:this.global_genders[key]}));

		
			
		document.body.dir=RtlLangs.includes(Config.langId)?"rtl":"ltr";
		document.documentElement.lang =Config.langId;


		// console.log([...this.country_codes]);
	}
	static chLang(langId){
		Config.langId=langId;
		CookieService.setCookie("info[lang_id]",langId);
		window.location.reload();
	}
}


export default Lang;