import React from 'react';


const Filter = ({closeFilter,opts,chosen,chOpt,title}) => {
	return (
		<section className="filter">
			<div className="bg-blue ">
				<div className="close" onClick={closeFilter} ></div>
				<div>
					<p>{title}</p>
					<ul>
						{opts.map(it => <li key={it.id} className={it.id === chosen?"active":""} onClick={() => chOpt(it)} >{it.text}</li>)}
					</ul>
				</div>
			</div>
		</section>
	)

}

export default Filter;