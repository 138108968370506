import React,{useContext,useState,useEffect} from 'react';
import AppContext from "../../AppContext";
import {useHistory,useParams} from "react-router-dom";
import {Api,Config,Lang} from "./../../services/Services";
import { ShopBtn,Stars } from '../utils/Utils';
import ProductRow from './ProductRow';

const ProductPage=()=>{
	const history=useHistory();
	const context=useContext(AppContext);
	const [game,gameUpd]=useState(null);
	const [product,productUpd]=useState(null);
	const {productId}=useParams();
	const StoreService=context.StoreService;
	
	const addRemove=()=>{
		productUpd({...product});
	}
	const load=async ()=>{
		if(productId){

			let res=await Api.getPackage(productId);
	 
			if(res.package){
				StoreService.crAgeCroups(res.package);
				productUpd(res.package);
			}
		}
	}
	useEffect(()=>{
		load();
	},[productId]);

	const backLink=obj=>{
		(obj.game?gameUpd(null):history.goBack());
	}
	const getName=obj=>{
		return obj?.display_name || obj?.text?.name;
	}

	if(!product){return null;}	

	return(
		<section className="product-show pad">
			<div className="game-details">
				<div className="top-wrap">
					<div className="top-area-with-btn">
						<span className="back-btn"  onClick={()=>backLink(game || product)} ></span>
						<div className="texts" >
							<h4>{getName(game || product)}</h4>
							<p  >{product?.text?.description}</p>
							<Stars obj={game || product} />
						</div>
					</div>
				</div>
				
				<div className="product-pic" >
					<div >
						<div className="ages" >{Lang.productsCatalog.icon_side_title_age}: {product.ages}</div>
						<img alt="" src={Config.server_url+"images/"+(game?game.icon:product.image)} />
					</div>
					<ShopBtn product={product} addRemove={addRemove} />
				</div>
				<Stars obj={game || product} />
			</div> 

			{!game && product.games && 
			<div className="more-games">
				<h3>{Lang.packageDetails.games_in_package}</h3>

				{product.games.map(childGame=>(
					<ProductRow key={childGame.game} product={product} game={childGame} gameSelect={()=>gameUpd(childGame)} />
				))}
			</div>
			}
		</section>

	)

	
}

export default ProductPage;
