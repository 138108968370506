import {FormService} from "../services/Services";

const compose=(...args)=>{
	if(!args){return null;}
	let cur=args.pop();
	while(args.length){
		cur=args.pop()(cur);
	}
	return cur;
}

const newKidRow=()=>({ name: "", birthday: FormService.dateToStr(new Date()), gender: "male", icon: 0 });


export {compose,newKidRow};